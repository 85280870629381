import { Form, Select } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";

export default function CustomSelect(props) {
  const [inputContent, setInputContent] = useState(null);
  // const [value, setValue] = useState("");
  // const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    if (props.handleChange) {
      props.handleChange(value);
    }
    if (value.length) {
      setInputContent(value);
    } else {
      setInputContent(null);
    }
  };
  useEffect(() => {
    setInputContent(props.value);
  }, [props.value]);
  const { Option } = Select;
  return (
    <InputContainer
      label={props.title}
      name={props?.name}
      rules={props?.rules}
      className={!inputContent ? "" : "labelHold"}
      getValueProps={(value) => setInputContent(value)}>
      <Select
        onChange={changeHandler}
        suffixIcon={
          props?.suffix || (
            <FaChevronDown style={{ color: "black", fontSize: "20px" }} />
          )
        }
        value={inputContent}>
        {props.options ? (
          props.options.map((item) => <Option value={item}>{item}</Option>)
        ) : (
          <>
            <Option value='USA'>USA</Option>
            <Option value='UK'>UK</Option>
            <Option value='UAE'>UAE</Option>
          </>
        )}
      </Select>
    </InputContainer>
  );
}
const InputContainer = styled(Form.Item)`
  margin-bottom: 2.3em;
  position: relative;
  .ant-select {
    position: relative;
    width: 100%;
    display: flex;
    margin: 0;
    align-items: center;
    height: 60px;
    border: 1px solid var(--blue) !important;
    border-radius: 10px;
    overflow: hidden;
  }
  .ant-form-item-row {
    display: block;
  }
  .ant-form-item-label {
    overflow: unset;
    position: absolute;
    z-index: 22;
    display: inline-block;
    left: 12px;
    top: 13px;
    color: #969aa8 !important;
    transition: 200ms;
    font-weight: 400;
    font-size: 1.125em !important;
  }

  label {
    color: var(--blue-ordinary) !important;
    font-size: 1.125em !important;
    transition: 200ms;
    height: auto !important;
    &:after {
      display: none;
    }
    &:before {
      display: none !important;
    }
  }
  &:focus,
  &:focus-within {
    box-shadow: none;
    .ant-form-item-label {
      top: -12px;
      background-color: #fff;
      label {
        font-size: 12px !important;
      }
    }
  }

  .ant-form-item {
    width: 100% !important;
    margin-bottom: 0;
  }

  .ant-select-show-arrow,
  .ant-select {
    width: 100% !important;
    .ant-select-selector,
    .ant-select-focused {
      .ant-select-selection-item {
        line-height: 4;
      }
      height: 60px;
      border: none !important;
      background-color: var(--white-smoke) !important;
      &:focus,
      &:hover {
        box-shadow: none !important;
      }
    }
  }
`;
