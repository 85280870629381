export const TableData = [
  {
    key: "1",
    name: "Pedro Kilbourn",
    invitation: "Invite Pending",
    role: "Manager",
  },
  {
    key: "2",
    name: "Hope Ormiston",
    invitation: "Accepted",
    role: "Manager",
  },
  {
    key: "3",
    name: "Sonia Britt",
    invitation: "Accepted",
    role: "Manager",
  },
];

export const TableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 400,
    render: (text, record) => `${record.first_name} ${record.last_name}`,
  },
  {
    title: "Invitation",
    dataIndex: "invitation",
    key: "invitation",
    width: 300,
  },
  {
    title: "Role",
    dataIndex: "teamRole",
    key: "teamRole",
    width: 300,
  },
  // {
  //   title: "Actions",
  //   dataIndex: "action",
  //   key: "action",
  //   width: 300,
  //   render: () => (
  //     <div>
  //       <a
  //         href='#'
  //         className='px-4 py-2'
  //         style={{
  //           backgroundColor: "var(--blue)",
  //           borderRadius: 5,
  //           color: "var(--white)",
  //           fontSize: "1em",
  //           fontFamily: `"Gabarito", sans-serif`,
  //           marginRight: "1em",
  //         }}>
  //         Invite
  //       </a>
  //       <a
  //         href='#'
  //         style={{
  //           color: "var(--purple)",
  //           fontSize: "1em",
  //           fontFamily: `"Gabarito", sans-serif`,
  //         }}>
  //         Delete
  //       </a>
  //     </div>
  //   ),
  // },
];
