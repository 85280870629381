import React, { useState, useEffect } from "react";
import {
  TableColumns,
  TableData,
} from "../../../data/AdminData/ReportsTableData";
import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AddButton from "../../../components/AddButton";
import DocumentsTable from "../../../components/DocumentsTable";
import { Form, Modal, Popconfirm } from "antd";
import AddReports from "../../../components/modals/AddReports";
import AppButton from "../../../components/typography/button";
import EditReports from "../../../components/modals/EditReports";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getInvestor, getReports } from "../../../redux/actions/admin";
import axios from "axios";
import { deleteInvestor, deleteReports } from "../../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const Reports = () => {
  const reportData = useSelector((state) => state?.admin?.reports);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleDelete = (data) => {
    setLoading(true);
    deleteReports(data.id).then(() => {
      setLoading(false);
      dispatch(getReports());
    });
  };

  useEffect(() => {
    dispatch(getReports());
  }, []);

  const tableRows = [];
  reportData.map((item) => {
    tableRows.push({
      key: item?.id,
      name: item?.name,
      type: item?.type,
      date: item?.date,
      docs: item?.docs?.url,
      actions: (
        <div style={sectionStyle}>
          <Popconfirm
            title='Delete Report'
            description='Are you sure to delete this report?'
            onConfirm={() => {
              setSelected(item);
              handleDelete(item);
            }}
            onCancel={(e) => {}}
            okText='Yes'
            cancelText='No'>
            <AppButton
              htmlType='button'
              text={
                loading && selected.id === item.id ? (
                  <LoadingOutlined />
                ) : (
                  <AiOutlineDelete />
                )
              }
              style={deleteStyle}
            />
          </Popconfirm>
          <AppButton
            htmlType='button'
            text={<AiOutlineEdit />}
            style={editStyle}
            onClick={() => {
              setSelected(item);
              setEditModalVisible(true);
            }}
          />
        </div>
      ),
    });
  });
  return (
    <>
      <Layout>
        <AdminHeading heading='Reports' />
        <Modal
          title='Add Report'
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          <AddReports form={form} onCancel={() => setIsModalVisible(false)} />
        </Modal>
        <Modal
          title='Edit Report'
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={false}>
          <EditReports
            data={selected}
            onCancel={() => {
              setSelected("");
              setEditModalVisible(false);
            }}
          />
        </Modal>
        <AppButton
          htmlType='button'
          text='Add Report'
          style={{
            marginLeft: "auto",
            marginBottom: "1em",
            marginRight: "0",
          }}
          onClick={() => setIsModalVisible(true)}
        />
        <DocumentsTable
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              ellipsis: true,
            },
          ]}
          dataSource={tableRows}
        />
      </Layout>
    </>
  );
};

export default Reports;
