import React, { useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/actions/auth";

export default function Frontend({ children }) {
  const { loading } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    dispatch(setUser(user));
  }, []);

  return (
    <>
      <Header />
      <div className='d-lg-flex'>
        <Sidebar />
        <div className='pt-lg-0 pt-5' style={{ width: "100%" }}>
          <div style={{ padding: "2.25em 3em 3em", width: "100%" }}>
            {loading ? <div>Loading...</div> : children}
          </div>
        </div>
      </div>
    </>
  );
}
