import React from "react";
import { Card } from "./styles";

const InvestmentCard = ({ heading, subheading }) => {
  return (
    <>
      <Card>
        <p>{heading}</p>
        <p>{subheading}</p>
      </Card>
    </>
  );
};

export default InvestmentCard;
