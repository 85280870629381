import styled from "styled-components";

export const loginCardStyles = {
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  marginBottom: "5rem",
};

export const LoginArea = styled.div`
  height: calc(100vh - 157px);
  display: flex;
  justify-content: center;
  background-color: var(--white);
  align-items: center;
  & {
    @media (max-width: 576px) {
      padding: 0 2em;
    }
  }
`;
export const LoginCard = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 6em 0 3.5em;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  form {
    width: 100%;
    max-width: 325px;
    margin: auto;
    text-align: center;
  }
  h1 {
    cursor: pointer;
    font-family: "Gabarito", sans-serif !important;
    margin-bottom: 1.75em;
    font-size: 2rem;

    // display: flex;
    p,
    span {
      color: var(--blue-secondary);
      margin: 0;
    }
    span {
      margin: 0 0.2em;
    }
    p {
      font-weight: 400;
      &.active {
        font-weight: 700;
      }
    }
  }
  .loginBtn {
    width: 100%;
    max-width: 228px;
    padding: 0.4em 0;
    background-color: var(--blue);
    border-radius: 5px;
    color: var(--white);
    font-size: 1.667em;
    margin-bottom: 0.75em;
  }
`;
