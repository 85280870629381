import styled from "styled-components";

export const HomeContent = styled.div`
  input {
    width: 100%;
    max-width: 325px;
    height: 59px;
    margin: 0.5em 0 1.75em;
    background-color: transparent;
    border-radius: 6px;
    border-color: var(--blue) !important;
    font-size: 18px;
    font-weight: 400;
    color: var(--blue-ordinary);
    display: block;
    padding-left: 1.25em;
  }
  button {
    width: 136px;
    height: 50px;
    background-color: var(--blue);
    border-radius: 5px;
    color: var(--white);
    font-size: 20px;
    font-family: "Gabarito", sans-serif !important;
  }
  .custom-placeholder-color::placeholder {
    color: #0a083b;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
`;
