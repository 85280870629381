import {
  GET_COMPANY_TEAM,
  GET_USER_FORUM,
  GET_SINGLE_FORUM,
  RESET_SINGLE_FORUM,
  GET_TICKETS,
  GET_FEEDBACK,
} from "../constant";

const initState = {
  team: [],
  tickets: [],
  feedback: [],
  userForum: {},
  singleForum: {},
};

const companyReducer = function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPANY_TEAM:
      return {
        ...state,
        team: payload,
      };
    case GET_USER_FORUM:
      return {
        ...state,
        userForum: payload,
      };
    case GET_SINGLE_FORUM:
      return {
        ...state,
        singleForum: payload,
      };
    case RESET_SINGLE_FORUM:
      return {
        ...state,
        singleForum: payload,
      };
    case GET_TICKETS:
      return {
        ...state,
        tickets: payload,
      };
    case GET_FEEDBACK:
      return {
        ...state,
        feedback: payload,
      };

    default:
      return { ...state };
  }
};
export default companyReducer;
