import React, { useEffect, useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import DragDropUpload from "../typography/dragDropUpload";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { updateCompany } from "../../utils/adminApis";
import { getCompanies } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";

export default function EditCompany({ data, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value) => {
    setLoading(true);
    axios({
      method: "put",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/update",
      data: {
        email: value.email,
        displayName: value.first_name + " " + value.last_name,
        uid: data.id,
      },
    })
      .then((response) => {
        updateCompany(value, data.id).then(() => {
          setLoading(false);
          onCancel();
          dispatch(getCompanies());
        });
      })
      .catch((error) => {
        form.setFields([
          {
            name: "email",
            errors: [error.response.data.message],
          },
        ]);
      });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='company_name'
          rules={[
            { required: true, message: "Please, enter the Company Name!" },
          ]}
          labelName='Company Name'
          inputType='text'
        />
        <CustomInput
          name='first_name'
          rules={[{ required: true, message: "Please, enter the First Name!" }]}
          labelName='First Name'
          inputType='text'
        />
        <CustomInput
          name='last_name'
          rules={[{ required: true, message: "Please, enter the Last Name!" }]}
          labelName='Last Name'
          inputType='text'
        />
        <CustomInput
          name='email'
          rules={[{ required: true, message: "Please, enter the email!" }]}
          labelName='Email'
          inputType='email'
        />
        <CustomInput
          name='total_investment'
          rules={[
            {
              required: true,
              message: "Please, enter the total investment!",
            },
          ]}
          labelName='Total Investment'
          inputType='text'
        />

        {/* <DragDropUpload
          label="Docs"
          name="file"
          multiple="false"
          rules={[
            {
              required: true,
              message: "Please upload file!",
            },
          ]}
        /> */}
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Edit Company"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
