import {
  GET_INVESTOR,
  GET_COMPANIES,
  GET_PORTFOLIO,
  GET_REPORTS,
  GET_DEALS,
  GET_NETWORK,
  GET_FORUM,
  GET_ASSETS_UNDER_MANAGEMENT,
  GET_ASSETS_UNDER_MANAGEMENT_HISTORY,
} from "../constant";

const initState = {
  investor: [],
  companies: [],
  portfolio: [],
  reports: [],
  deals: [],
  network: [],
  forum: [],
  managementAssets: {},
  managementAssetsHistory: [],
};

const appReducer = function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INVESTOR:
      return {
        ...state,
        investor: payload,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companies: payload,
      };
    case GET_PORTFOLIO:
      return {
        ...state,
        portfolio: payload,
      };
    case GET_REPORTS:
      return {
        ...state,
        reports: payload,
      };
    case GET_DEALS:
      return {
        ...state,
        deals: payload,
      };
    case GET_NETWORK:
      return {
        ...state,
        network: payload,
      };
    case GET_FORUM:
      return {
        ...state,
        forum: payload,
      };

    case GET_ASSETS_UNDER_MANAGEMENT:
      return {
        ...state,
        managementAssets: payload,
      };
    case GET_ASSETS_UNDER_MANAGEMENT_HISTORY:
      return {
        ...state,
        managementAssetsHistory: payload,
      };
    default:
      return { ...state };
  }
};

export default appReducer;
