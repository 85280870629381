import styled from "styled-components";

export const Button = styled.button`
  width: 178px;
  height: 50px;
  margin-left: auto;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue);
  border-radius: 5px;
  font-size: 1.67em;
  color: var(--white);
  & {
    @media (max-width: 768px) {
      width: 140px;
      height: 40px;
    }
  }
`;
