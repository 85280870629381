import React, { useState, useEffect } from "react";

import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AddButton from "../../../components/AddButton";
import DocumentsTable from "../../../components/DocumentsTable";
import { Modal, Popconfirm } from "antd";
import AppButton from "../../../components/typography/button";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getTickets } from "../../../redux/actions/company";
import { LoadingOutlined } from "@ant-design/icons";
import { deleteTicket } from "../../../utils/companyApi";
import EditTicket from "../../../components/modals/EditTicket";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const Tickets = () => {
  const ticketData = useSelector((state) => state?.company?.tickets);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const handleDelete = (data) => {
    setLoading(true);
    deleteTicket(data.id).then(() => {
      setLoading(false);
      dispatch(getTickets());
    });
  };

  useEffect(() => {
    dispatch(getTickets());
  }, []);

  const TableColumns = [
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
  ];

  const tableRows = [];
  ticketData.map((item) => {
    tableRows.push({
      key: item.id,
      topic: item.topic,
      description: item.description,
      status: item.status,
      actions: (
        <div style={sectionStyle}>
          <Popconfirm
            title='Delete Ticket'
            description='Are you sure to delete this ticket?'
            onConfirm={() => {
              setSelected(item);
              handleDelete(item);
            }}
            onCancel={(e) => {}}
            okText='Yes'
            cancelText='No'>
            <AppButton
              htmlType='button'
              text={
                loading && selected.id === item.id ? (
                  <LoadingOutlined />
                ) : (
                  <AiOutlineDelete />
                )
              }
              style={deleteStyle}
            />
          </Popconfirm>
          <AppButton
            htmlType='button'
            text={<AiOutlineEdit />}
            style={editStyle}
            onClick={() => {
              setSelected(item);
              setEditModalVisible(true);
            }}
          />
        </div>
      ),
    });
  });
  return (
    <>
      <Layout>
        <AdminHeading heading='Tickets' />

        <Modal
          title='Edit Ticket'
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={false}>
          <EditTicket
            data={selected}
            onCancel={() => setEditModalVisible(false)}
          />
        </Modal>
        {/*  <AppButton
          htmlType='button'
          text='Add Deal'
          style={{
            marginLeft: "auto",
            marginBottom: "1em",
            marginRight: "0",
          }}
          onClick={() => setIsModalVisible(true)}
        /> */}
        <DocumentsTable
          odd='odd'
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              ellipsis: true,
              // render: (text, record) => (
              //   <div style={sectionStyle}>
              //     <Popconfirm
              //       title='Delete Ticket'
              //       description='Are you sure to delete this ticket?'
              //       onConfirm={() => {
              //         setSelected(record);
              //         handleDelete(record);
              //       }}
              //       onCancel={(e) => {}}
              //       okText='Yes'
              //       cancelText='No'>
              //       <AppButton
              //         htmlType='button'
              //         text={
              //           loading && selected.id === record.id ? (
              //             <LoadingOutlined />
              //           ) : (
              //             <AiOutlineDelete />
              //           )
              //         }
              //         style={deleteStyle}
              //       />
              //     </Popconfirm>
              //     <AppButton
              //       htmlType='button'
              //       text={<AiOutlineEdit />}
              //       style={editStyle}
              //       onClick={() => {
              //         setSelected(record);
              //         setEditModalVisible(true);
              //       }}
              //     />
              //   </div>
              // ),
            },
          ]}
          dataSource={tableRows}
        />
      </Layout>
    </>
  );
};

export default Tickets;
