import React from "react";
import styled from "styled-components";
import { Button } from "antd";

export default function AppButton(props) {
  return <CustomButton {...props}>{props.text}</CustomButton>;
}
const CustomButton = styled(Button)`
  font-family: "Gabarito", sans-serif;
  padding: 0.4em 0.5em;
  background-color: var(--blue);
  border-radius: 5px;
  color: var(--white);
  font-size: 20px;
  height: auto;
  min-width: 136px;
  display: block;
  margin: auto;
  justify-content: end;
`;
