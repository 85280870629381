import React from "react";
import { Heading } from "./styles";

const AdminHeading = ({ heading }) => {
  return (
    <>
      <Heading>{heading}</Heading>
    </>
  );
};

export default AdminHeading;
