import styled from "styled-components";

export const Links = styled.div`
  width: 100%;
  max-width: 430px;
  min-width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  gap: 3.75rem;

  p {
    font-family: "Gabarito", sans-serif !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    color: var(--blue-secondary);
    cursor: pointer;
    &:hover,
    &.active {
      border-bottom: 2px solid var(--blue-secondary);
    }
  }
  &.odd {
    margin-bottom: 3.5em;
    p {
      font-size: 20px;
    }
  }
  &.odd1 {
    max-width: 522px;
  }
  &.odd2 {
    max-width: 800px;
    & {
      @media (max-width: 576px) {
        flex-wrap: wrap;
        p {
          margin: 0.5em;
        }
      }
    }
  }
`;
export const InvestorsPara = styled.p`
  font-size: 18px;
  color: var(--text, #57586e);
  font-family: "Gabarito", sans-serif;
  margin-bottom: 2em;
`;
