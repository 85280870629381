import { db, storage, auth } from "../firebase";

export const getCurrentUser = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      const doc = await db.collection("users").doc(uid).get();

      if (doc.data()) {
        return resolve({ id: doc.id, ...doc.data() });
      } else {
        return resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
export const getCompanyUser = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      const doc = await db.collection("companies").doc(uid).get();

      if (doc.data()) {
        return resolve({ id: doc.id, ...doc.data() });
      } else {
        return resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
export const getInvestorUser = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      const doc = await db.collection("investors").doc(uid).get();
      if (doc.data()) {
        return resolve({ id: doc.id, ...doc.data() });
      } else {
        return resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
