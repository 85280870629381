import {
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNIN_START,
  SET_USER,
  LOGOUT_SUCCESS,
  LOGOUT_START,
  LOGOUT_ERROR,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_START,
} from "../constant";

const initState = {
  loading: false,
  currentUser: null,
  error: null,
};

const appReducer = function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case SIGNIN_START:
    case LOGOUT_START:
    case REGISTER_START:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: null,
      };
    case SET_USER:
      return {
        ...state,
        loading: false,
        currentUser: payload,
      };
    case SIGNIN_SUCCESS:
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: payload,
      };
    case SIGNIN_ERROR:
    case REGISTER_ERROR:
    case LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return { ...state };
  }
};

export default appReducer;
