import {
  GET_INVESTOR,
  GET_COMPANIES,
  GET_PORTFOLIO,
  GET_REPORTS,
  GET_DEALS,
  GET_NETWORK,
  GET_FORUM,
  GET_ASSETS_UNDER_MANAGEMENT,
  GET_ASSETS_UNDER_MANAGEMENT_HISTORY,
} from "../constant";
import { db } from "../../firebase";

export const getInvestor = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("investors").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_INVESTOR, payload: temp });
};
export const getCompanies = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("companies").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_COMPANIES, payload: temp });
};
export const getPortfolio = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("portfolio").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_PORTFOLIO, payload: temp });
};
export const getReports = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("reports").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_REPORTS, payload: temp });
};
export const getDeals = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("deals").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_DEALS, payload: temp });
};
export const getNetwork = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("network").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_NETWORK, payload: temp });
};
export const getForum = () => async (dispatch) => {
  const snap = await db.collection("forum").get();

  const snapPromises = snap.docs.map(async (doc) => {
    const comment = await db
      .collection("forum")
      .doc(doc.id)
      .collection("comments")
      .get();

    return { id: doc.id, ...doc.data(), commentCount: comment.size };
  });

  const temp = await Promise.all(snapPromises);
  dispatch({ type: GET_FORUM, payload: temp });
};

export const getManagementAssets = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("management_assets").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_ASSETS_UNDER_MANAGEMENT, payload: temp });
};

export const getManagementAssetsHistory = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("management_assets_history").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_ASSETS_UNDER_MANAGEMENT_HISTORY, payload: temp });
};
