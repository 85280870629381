import { db, storage, auth } from "../../firebase";
import {
  GET_COMPANY_TEAM,
  GET_USER_FORUM,
  GET_SINGLE_FORUM,
  RESET_SINGLE_FORUM,
  GET_TICKETS,
  GET_FEEDBACK,
} from "../constant";

export const getTeam = (uid) => async (dispatch) => {
  const temp = [];
  const snap = await db
    .collection("companies")
    .where("invitedBy", "==", uid)
    .get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_COMPANY_TEAM, payload: temp });
};

export const getUserForum = (uid) => async (dispatch) => {
  const snap = await db.collection("forum").where("user.id", "==", uid).get();

  const snapPromises = snap.docs.map(async (doc) => {
    const comment = await db
      .collection("forum")
      .doc(doc.id)
      .collection("comments")
      .get();

    return { id: doc.id, ...doc.data(), commentCount: comment.size };
  });

  const temp = await Promise.all(snapPromises);

  dispatch({ type: GET_USER_FORUM, payload: temp });
};
export const getSingleForum = (id) => async (dispatch) => {
  const forum = (await db.collection("forum").doc(id).get()).data();
  const comments = [];
  const snap = await db
    .collection("forum")
    .doc(id)
    .collection("comments")
    .get();
  snap.forEach((doc) => comments.push({ id: doc.id, ...doc.data() }));
  forum.comments = comments;

  dispatch({ type: GET_SINGLE_FORUM, payload: forum });
};

export const resetSingleForum = () => async (dispatch) => {
  dispatch({ type: RESET_SINGLE_FORUM, payload: {} });
};

export const getTickets = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("tickets").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_TICKETS, payload: temp });
};

export const getFeedback = () => async (dispatch) => {
  const temp = [];
  const snap = await db.collection("feedback").get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_FEEDBACK, payload: temp });
};
