import React, { useState, useEffect } from "react";
import {
  TableColumns,
  TableData,
} from "../../../data/AdminData/PortfolioTableData";
import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AddButton from "../../../components/AddButton";
import DocumentsTable from "../../../components/DocumentsTable";
import { Modal, Popconfirm } from "antd";
import AddPortfolio from "../../../components/modals/AddPortfolio";
import AppButton from "../../../components/typography/button";
import EditPortfolio from "../../../components/modals/EditPortfolio";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getInvestor, getPortfolio } from "../../../redux/actions/admin";
import axios from "axios";
import { deleteInvestor, deletePortfolio } from "../../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const Portfolio = () => {
  const portfolioData = useSelector((state) => state?.admin?.portfolio);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();

  const handleDelete = (data) => {
    setLoading(true);
    deletePortfolio(data.id).then(() => {
      setLoading(false);
      dispatch(getPortfolio());
    });
  };

  useEffect(() => {
    dispatch(getPortfolio());
  }, []);

  const tableRows = [];
  portfolioData.map((item) => {
    tableRows.push({
      key: item?.id,
      name: item?.name,
      docs: item?.docs,
      investment_date: item?.investment_date,
      investment_amount: `$${Number(item?.investment_amount).toLocaleString(
        "en-US"
      )}`,
      type: item?.type,
      investment_memo: item?.investment_memo,
      actions: (
        <div style={sectionStyle}>
          <Popconfirm
            title='Delete Portfolio'
            description='Are you sure to delete this portfolio?'
            onConfirm={() => {
              setSelected(item);
              handleDelete(item);
            }}
            onCancel={(e) => {}}
            okText='Yes'
            cancelText='No'>
            <AppButton
              htmlType='button'
              text={
                loading && selected.id === item.id ? (
                  <LoadingOutlined />
                ) : (
                  <AiOutlineDelete />
                )
              }
              style={deleteStyle}
            />
          </Popconfirm>
          <AppButton
            htmlType='button'
            text={<AiOutlineEdit />}
            style={editStyle}
            onClick={() => {
              setSelected(item);
              setEditModalVisible(true);
            }}
          />
        </div>
      ),
    });
  });
  return (
    <>
      <Layout>
        <AdminHeading heading='Portfolio' />
        <Modal
          title='Add Portfolio'
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          <AddPortfolio onCancel={() => setIsModalVisible(false)} />
        </Modal>
        <Modal
          title='Edit Portfolio'
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={false}>
          <EditPortfolio
            data={selected}
            onCancel={() => {
              setSelected("");
              setEditModalVisible(false);
            }}
          />
        </Modal>
        <AppButton
          htmlType='button'
          text='Add Portfolio'
          style={{
            marginLeft: "auto",
            marginBottom: "1em",
            marginRight: "0",
          }}
          onClick={() => setIsModalVisible(true)}
        />
        <DocumentsTable
          odd='odd'
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              ellipsis: true,
            },
          ]}
          dataSource={tableRows}
        />
      </Layout>
    </>
  );
};

export default Portfolio;
