import { Form } from "antd";
import styled from "styled-components";

export const Links = styled.div`
  width: 100%;
  max-width: 363px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  p {
    font-size: 26px;
    font-family: "Gabarito", sans-serif !important;
    margin: 0;
    color: var(--blue-secondary);
    cursor: pointer;
    &:hover,
    &.active {
      border-bottom: 2px solid var(--blue-secondary);
    }
  }
`;
export const SettingForm = styled(Form)`
  // .ant-form-item {
  //   margin-bottom: 0;
  //   height: 81px;
    // input {
    //   height: 59px;
    //   padding: 0.3em 1.45em;
    //   background-color: transparent;
    //   border-color: var(--blue);
    //   font-size: 1.286em;
    //   font-weight: 400;
    //   font-family: "Ubuntu", sans-serif;
    //   color: var(--blue-ordinary);
    //   &::placeholder {
    //     color: var(--blue-ordinary);
    //   }
    // }
    .submitBtn {
      width: 100%;
      height: 50px;
      background-color: var(--blue) !important;
      font-size: 1.43em;
      font-weight: 400;
      font-family: "Gabarito", sans-serif;
    }
    &.ant-form-item-has-error {
      input {
        &::placeholder {
          color: #ff4d4f;
        }
      }
    }
  }
`;
export const IntegrateContent = styled.div`
  p {
    font-size: 18px;
    font-family: "Gabarito", sans-serif;
    color: var(--purple);
  }
`;
