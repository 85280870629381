import React, { useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import DragDropUpload from "../typography/dragDropUpload";
import axios from "axios";
import { generate } from "@wcj/generate-password";
import { createCompany } from "../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";
import { getCompanies } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";

export default function AddCompany({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (value) => {
    setLoading(true);
    const password = await generate();
    axios({
      method: "post",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/create",
      data: {
        email: value.email,
        displayName: value.first_name + " " + value.last_name,
        password: password,
        role: "company",
      },
    })
      .then((response) => {
        createCompany(
          {
            ...value,
            role: "company",
            file: value.file.fileList[0].originFileObj,
          },
          response.data.uid
        ).then(() => {
          setLoading(false);
          onCancel();
          dispatch(getCompanies());
        });
      })
      .catch((error) => {
        setLoading(false);

        form.setFields([
          {
            name: "email",
            errors: [error.response.data.message],
          },
        ]);
      });
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='company_name'
          rules={[
            { required: true, message: "Please, enter the Company Name!" },
          ]}
          labelName='Company Name'
          inputType='text'
        />
        <CustomInput
          name='first_name'
          rules={[{ required: true, message: "Please, enter the First Name!" }]}
          labelName='First Name'
          inputType='text'
        />
        <CustomInput
          name='last_name'
          rules={[{ required: true, message: "Please, enter the Last Name!" }]}
          labelName='Last Name'
          inputType='text'
        />
        <CustomInput
          name='email'
          rules={[{ required: true, message: "Please, enter the email!" }]}
          labelName='Email'
          inputType='email'
        />
        <CustomInput
          name='total_investment'
          rules={[
            {
              required: true,
              message: "Please, enter the total investment!",
            },
          ]}
          labelName='Total Investment'
          inputType='text'
        />

        <DragDropUpload
          label='Docs'
          name='file'
          multiple='false'
          rules={[
            {
              required: true,
              message: "Please upload file!",
            },
          ]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Add Company"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
