export const TableData = [""];

export const TableColumns = [
  {
    title: "Topic Title",
    dataIndex: "topic_title",
    key: "topic_title",
    ellipsis: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },
  {
    title: "# Comments",
    dataIndex: "comments",
    key: "comments",
    ellipsis: true,
  },
  {
    title: "Who Posted",
    dataIndex: "posted",
    key: "posted",
    ellipsis: true,
  },
];
