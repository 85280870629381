import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "antd";
import { DatePicker } from "antd";

import { IoMdCalendar } from "react-icons/io";

export default function CustomDatePicker(props) {
  const [startDate, setStartDate] = useState(null);

  const onChange = (value) => {
    if (value) {
      setStartDate(value);
    } else {
      setStartDate(null);
    }
  };
  const dateFormat = "MM/DD/YYYY";
  return (
    <InputContainer
      label={props?.label || "Schedule Date"}
      className={!startDate ? "" : "labelHold"}
      name={props?.name}
      rules={props?.rules}
      getValueProps={(i) => setStartDate(i)}
    >
      <DatePicker
        placeholder=" "
        suffixIcon={props?.suffix || <IoMdCalendar />}
        onChange={onChange}
        format={dateFormat}
        value={startDate}
      />
    </InputContainer>
  );
}

const InputContainer = styled(Form.Item)`
  margin-bottom: 2.3em;
  position: relative;
  .ant-picker {
    position: relative;
    width: 100%;
    display: flex;
    margin-bottom: 0rem;
    align-items: center;
    height: 60px;
    border: 1px solid var(--blue);
    background-color: var(--white-smoke);
    border-radius: 10px;
  }
  .ant-picker-suffix {
    color: var(--blue);
    font-size: 2em;
  }
  label {
    color: var(--blue) !important;
    font-size: 1.125em !important;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    height: unset !important;
    transition: 200ms;
    &:after {
      display: none;
    }
    &:before {
      display: none !important;
    }
  }
  .ant-form-item-row {
    display: block;
  }
  .ant-form-item-label {
    overflow: unset;
    position: absolute;
    z-index: 22;
    display: inline-block;
    left: 12px;
    top: 13px;
    color: var(--blue-ordinary) !important;
    transition: 200ms;
    font-weight: 400;
    font-size: 1.125em;
    padding: 0;
    line-height: 1;
  }

  &:focus,
  &:focus-within {
    box-shadow: none;
    .ant-form-item-label {
      top: -16px;
      background: #fff;
      label {
        font-size: 12px !important;
      }
    }
  }
  .ant-picker-focused {
    box-shadow: none !important;
    outline: 0;
  }
  .inputLabel {
    position: absolute;
    z-index: 11;
    display: inline-block;
    left: 15px;
    top: 16px;
    color: #8b8b8b;
    // background-color: var(--colorWhite);
    transition: 500ms;
    font-weight: 400;
    font-size: 16px;
  }
  &:focus-within {
    .inputLabel {
      background-color: var(--colorWhite);
      font-weight: 400;
      font-size: 12px;
      top: 5px;
      left: 15px;
    }
  }
  .topInputLabel {
    position: absolute;
    z-index: 11;
    background-color: var(--colorWhite);
    font-weight: 400 !important;
    font-size: 12px;
    top: 5px;
    left: 15px;
  }
`;
