import React from "react";
import { Card } from "./styles";
import { useNavigate } from "react-router-dom";

const ForumCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <>
      <Card>
        <div>
          <p className='mainHeading'>{item.topic_title}</p>
          <p className='text'>{item.description}</p>
        </div>
        <div style={{ alignSelf: "end" }}>
          <button
            type='button'
            onClick={() => navigate(`/company/platform/forum/${item.id}`)}>
            Read More
          </button>
          <p style={{ textAlign: "center" }} className='text'>
            {item.commentCount} Comments
          </p>
        </div>
      </Card>
    </>
  );
};

export default ForumCard;
