import { Form } from "antd";
import styled from "styled-components";

export const SettingForm = styled(Form)`
  // .ant-form-item {
  //   margin-bottom: 0;
  //   height: 81px;
    // input {
    //   height: 59px;
    //   padding: 0.3em 1.45em;
    //   background-color: transparent;
    //   border-color: var(--blue);
    //   font-size: 1.286em;
    //   font-weight: 400;
    //   font-family: "Ubuntu", sans-serif;
    //   color: var(--blue-ordinary);
    //   &::placeholder {
    //     color: var(--blue-ordinary);
    //   }
    // }
    .submitBtn {
      width: 100%;
      height: 50px;
      background-color: var(--blue) !important;
      font-size: 1.43em;
      font-weight: 400;
      font-family: "Gabarito", sans-serif;
    }
    &.ant-form-item-has-error {
      input {
        &::placeholder {
          color: #ff4d4f;
        }
      }
    }
  }
`;
export const IntegrateContent = styled.div`
  p {
    font-size: 1.5em;
    font-family: "Gabarito", sans-serif;
    color: var(--purple);
    margin-bottom: 1.5em;
  }
`;
