import { Image } from "antd";
import moment from "moment";
export const PrivateTableData = [];

export const PrivateTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Icon",
    dataIndex: "docs",
    key: "docs",
    render: (text) => (
      <Image
        src={text}
        style={{ borderRadius: "50%", width: "70px", height: "70px" }}
      />
    ),
  },
  {
    title: "Investment Date",
    dataIndex: "date",
    key: "date",
    render: (text) => moment(text, "x").format("MM/DD/YYYY"),
  },
  {
    title: "Investment Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Investment Memo",
    dataIndex: "memo",
    key: "memo",
  },
];
