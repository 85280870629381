import styled from "styled-components";
import { Form } from "antd";

export const InputContainer = styled(Form.Item)`
  margin-bottom: 2.3em;
  position: relative;
  label {
    color: var(--blue-ordinary) !important;
    // font-family: Inter;
    font-size: 1.125em !important;
    /* background-color: var(--white-smoke); */
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.24px;
    transition: 200ms;
    height: auto !important;
    &:after {
      display: none;
    }
    &:before {
      display: none !important;
    }
  }
  .ant-input {
    width: 100%;
    height: 60px;
    background: var(--white-smoke) !important;
    border-radius: 8px;
    border: 1px solid var(--blue);
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  .ant-form-item-row {
    display: block;
  }
  .ant-form-item-label {
    overflow: unset;
    position: absolute;
    z-index: 22;
    display: inline-block;
    left: 12px;
    top: 13px;
    color: var(--blue-ordinary) !important;
    transition: 200ms;
    font-weight: 400;
    font-size: 1.125em;
    padding: 0;
    line-height: 1;
  }

  &:focus,
  &:focus-within {
    box-shadow: none;
    .ant-form-item-label {
      top: -10px;
      background: #fff;
      label {
        font-size: 12px !important;
      }
    }
  }
`;
