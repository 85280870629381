import styled from "styled-components";
import { Table } from "antd";

export const InvestmentTable = styled(Table)`
  border-radius: 10px;
  overflow: hidden;
  text-transform: capitalize;
  .ant-table {
    border-radius: 10px;
    overflow: hidden;
  }

  tr {
    th,
    td {
      background-color: var(--white) !important;
      border-bottom-color: var(--white-smoke) !important;
      font-weight: 400 !important;
      font-family: "Gabarito", sans-serif !important;
      color: var(--purple) !important;
    }
    th {
      font-size: 22px;

      &::before {
        display: none;
      }
      &:first-child {
        padding-left: 1em;
      }
    }
    td {
      font-size: 1.286em;
      &:first-child {
        padding-left: 1.5em;
        font-family: "Gabarito", sans-serif !important;
      }
    }
  }
  &.odd {
    tr {
      th,
      td {
        &:first-child {
          padding-left: 1em;
        }
      }
      th {
        font-size: 22px;
      }
      td {
        font-size: 1.25em;
        padding-left: 1.5em !important;
      }
    }
  }
  & {
    @media (max-width: 1280px) {
      tr {
        th {
          font-size: 1.5em !important;
          &:first-child {
            padding-left: 1.5em;
          }
        }
        td {
          font-size: 1.15em !important;
          &:first-child {
            padding-left: 2em;
          }
        }
      }
    }
    @media (max-width: 992px) {
      tr {
        th {
          font-size: 1.25em !important;
        }
        td {
          font-size: 1.05em !important;
        }
      }
    }
    @media (max-width: 768px) {
      tr {
        th {
          font-size: 1.05em !important;
        }
        td {
          font-size: 0.95em !important;
        }
      }
    }
  }
`;
