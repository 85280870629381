import React from "react";
import { Table } from "antd";
import { InvestmentTable } from "./styles";

const DocumentsTable = ({ odd, columns, dataSource, loading }) => {
  return (
    <>
      <InvestmentTable
        loading={loading}
        className={odd}
        columns={columns}
        dataSource={dataSource}
      />
    </>
  );
};

export default DocumentsTable;
