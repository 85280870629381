import styled from "styled-components";

export const Bar = styled.div`
  & {
    @media (max-width: 991px) {
      position: fixed;
      z-index: 1;
      transition: all 400ms ease-in-out;
      width: 50px;
      &.active {
        width: 265px;
      }
    }
  }
`;
export const SidePanel = styled.div`
  overflow: auto;
  height: 100%;
  min-height: calc(100vh - 63.1719px);
  background-color: var(--white);
  padding-top: 2.5rem;
  width: 215px;
  border-top: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column; /* Arrange links vertically */

  font-family: "Gabarito", sans-serif;

  a {
    font-size: 18px;
    display: block;
    /* text-align: center;*/
    margin-bottom: 2.7em;
    text-align: left; /* Align the text to the left */

    margin-left: 3.5em;
    color: var(--purple);
    position: relative;
    transition: all 400ms ease-in-out;
    &:hover {
      color: var(--blue-secondary);
    }
    &.active {
      color: var(--blue-secondary);
      &::after {
        content: "";
        width: 4px;
        height: 48px;
        background-color: var(--blue-secondary);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  & {
    @media (max-width: 991px) {
      height: 100vh;
      position: fixed;
      transition: all 300ms ease-in-out;
      left: -100%;
      top: 0;
      padding-top: 7rem;
      &.active {
        left: 0;
      }
    }
  }
`;
export const SlideArrow = styled.div`
  display: none;
  cursor: pointer;
  box-shadow: 0 3px 4px 3px rgb(0 0 0 / 0.2);
  & {
    @media (max-width: 991px) {
      display: block;
      padding: 1rem;
      background-color: var(--white);
      position: absolute;
      top: 8px;
      right: 6px;
      svg {
        font-size: 1.5rem;
        transition: all 500ms ease-in-out;
      }
    }
  }
`;
