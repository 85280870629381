import React, { useState, useEffect } from "react";
import { TableColumns } from "../../../data/CompanyData/TeamTableData";
import DocumentsTable from "../../../components/DocumentsTable";
import Layout from "../../../layout/Frontend";
import { TeamContent } from "./styles";
import AdminHeading from "../../../components/AdminHeading";
import { useDispatch, useSelector } from "react-redux";
import InviteTeamMember from "../../../components/modals/InviteTeamMember";
import { getTeam } from "../../../redux/actions/company";
import { Form, Modal, Popconfirm, message } from "antd";
import { addTeamMember, deleteTeamMember } from "../../../utils/companyApi";
import { generate } from "@wcj/generate-password";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { inviteAgain } from "../../../utils/helper";

const CompanyTeam = () => {
  const user = useSelector((state) => state?.auth?.currentUser);
  const teamData = useSelector((state) => state?.company?.team);
  const [loading, setLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    if (user?.id) dispatch(getTeam(user?.id));
  }, [user]);

  const onFinish = async (value) => {
    setLoading(true);
    const password = await generate();
    axios({
      method: "post",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/create",
      data: {
        email: value.email,
        displayName: value.first_name + " " + value.last_name,
        password: password,
        role: "company",
      },
    })
      .then((response) => {
        addTeamMember(
          {
            ...value,
            role: "company",
            invitedBy: user.id,
            invitationStatus: "invite pending",
          },
          response.data.uid
        ).then(() => {
          dispatch(getTeam(user.id));
          setLoading(false);
          setIsModalVisible(false);
          form.resetFields();
        });
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleDelete = (data) => {
    setLoading(true);
    deleteTeamMember(data.id).then(() => {
      setLoading(false);
      dispatch(getTeam(user?.id));
    });
  };

  // table rows
  const tableRows = [];
  teamData &&
    teamData.map((item) => {
      tableRows.push({
        ...item,
        invitation: item.invitationStatus,
        key: item.id,
        actions: (
          <div>
            <sapn
              className='px-4 py-2'
              onClick={() => inviteAgain(item, setInviteLoading, message)}
              style={{
                backgroundColor: "var(--blue)",
                borderRadius: 5,
                color: "var(--white)",
                fontSize: "1em",
                fontFamily: `"Gabarito", sans-serif`,
                marginRight: "1em",
                cursor: "pointer",
              }}>
              {inviteLoading ? <LoadingOutlined /> : "Invite"}
            </sapn>
            <Popconfirm
              title='Are you sure to delete this team member?'
              onConfirm={() => handleDelete(item)}
              onCancel={() => {}}
              okText='Yes'
              cancelText='No'>
              <sapn
                style={{
                  color: "var(--purple)",
                  fontSize: "1em",
                  fontFamily: `"Gabarito", sans-serif`,
                  cursor: "pointer",
                }}>
                Delete
              </sapn>
            </Popconfirm>
          </div>
        ),
      });
    });
  return (
    <>
      <Layout>
        <TeamContent>
          <AdminHeading heading='Team' />
          <a href='#' onClick={() => setIsModalVisible(true)}>
            Invite Team Member
          </a>
        </TeamContent>
        <Modal
          title='Invite Team Member'
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          <InviteTeamMember
            onFinish={onFinish}
            form={form}
            loading={loading}
            onCancel={() => setIsModalVisible(false)}
          />
        </Modal>
        <DocumentsTable
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              width: 300,
            },
          ]}
          dataSource={tableRows}
        />
      </Layout>
    </>
  );
};

export default CompanyTeam;
