import React, { useState, useEffect } from "react";
import {
  TableColumns,
  TableData,
} from "../../../data/AdminData/InvestorsTableData";
import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AppButton from "../../../components/typography/button";
import DocumentsTable from "../../../components/DocumentsTable";
import { Modal, Popconfirm, Button } from "antd";
import AddInvestor from "../../../components/modals/AddInvestor";
import EditInvestor from "../../../components/modals/EditInvestor";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getInvestor } from "../../../redux/actions/admin";
import axios from "axios";
import { deleteInvestor } from "../../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const Investors = () => {
  const data = useSelector((state) => state?.admin?.investor);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();

  const handleDelete = (data) => {
    setLoading(true);
    axios({
      method: "delete",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/delete",
      data: {
        uid: data.id,
      },
    }).then((response) => {
      deleteInvestor(data.id).then(() => {
        setLoading(false);
        dispatch(getInvestor());
      });
    });
  };

  useEffect(() => {
    dispatch(getInvestor());
  }, []);

  const tableRows = [];
  data &&
    data.map((item, index) => {
      tableRows.push({
        key: item?.id,
        first_name: item?.first_name,
        last_name: item?.last_name,
        email: item?.email,
        investment_amount: `$${Number(item?.investment_amount).toLocaleString(
          "en-US"
        )}`,
        investment_status: item?.investment_status,
        docs: item?.docs?.url,
        id: item?.id,
        actions: (
          <div style={sectionStyle}>
            <Popconfirm
              title='Delete Investor'
              description='Are you sure to delete this investor?'
              onConfirm={() => {
                setSelected(item);
                handleDelete(item);
              }}
              onCancel={(e) => {}}
              okText='Yes'
              cancelText='No'>
              <AppButton
                htmlType='button'
                text={
                  loading && selected.id === item.id ? (
                    <LoadingOutlined />
                  ) : (
                    <AiOutlineDelete />
                  )
                }
                style={deleteStyle}
              />
            </Popconfirm>
            <AppButton
              htmlType='button'
              text={<AiOutlineEdit />}
              style={editStyle}
              onClick={() => {
                setSelected(item);
                setEditModalVisible(true);
              }}
            />
          </div>
        ),
      });
    });
  return (
    <>
      <Layout>
        <Modal
          title='Add Investor'
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          <AddInvestor onCancel={() => setIsModalVisible(false)} />
        </Modal>
        <Modal
          title='Edit Investor'
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={false}>
          <EditInvestor
            data={selected}
            onCancel={() => {
              setSelected("");
              setEditModalVisible(false);
            }}
          />
        </Modal>

        <AdminHeading heading='Investors' />
        <AppButton
          htmlType='button'
          text='Add Investor'
          style={{
            marginLeft: "auto",
            marginBottom: "1em",
            marginRight: "0",
          }}
          onClick={() => setIsModalVisible(true)}
        />
        <DocumentsTable
          odd='odd'
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              ellipsis: true,
            },
          ]}
          dataSource={tableRows || []}
        />
      </Layout>
    </>
  );
};

export default Investors;
