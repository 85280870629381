import React, { useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import { useDispatch, useSelector } from "react-redux";
import { createForum } from "../../utils/adminApis";
import { getForum } from "../../redux/actions/admin";
import { LoadingOutlined } from "@ant-design/icons";
export default function AddForum({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.auth?.currentUser);

  const onFinish = async (value) => {
    setLoading(true);
    createForum({ ...value, user })
      .then(() => {
        setLoading(false);
        onCancel();
        dispatch(getForum());
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='topic_title'
          rules={[
            { required: true, message: "Please, enter the Topic Title!" },
          ]}
          labelName='Topic Title'
          inputType='text'
        />
        <CustomInput
          name='description'
          rules={[
            { required: true, message: "Please, enter the Description!" },
          ]}
          labelName='Description'
          inputType='text'
        />
        {/* <CustomInput
          name="comments"
          rules={[{ required: true, message: "Please, enter the Comments!" }]}
          labelName="Comments"
          inputType="text"
        />
        <CustomInput
          name="posted"
          rules={[
            {
              required: true,
              message: "Please, enter the Who Posted!",
            },
          ]}
          labelName="Who Posted"
          inputType="text"
        /> */}
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Add Forum"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
