import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 155px;
  padding: 0 2.5em 0.5em 4em;
  background-color: var(--white);
  border-radius: 10px;
  p {
    margin: 0;
    font-family: "Gabarito", sans-serif;
    &.mainHeading {
      font-size: 1.86em;
      color: var(--blue-ordinary);
      font-family: "Gabarito", sans-serif;
      margin-bottom: 0.6em;
    }
    &.text {
      font-size: 1.286em;
      color: var(--purple);
    }
  }
  button {
    padding: 0.55em 0.9em;
    font-size: 1.286em;
    font-family: "Gabarito", sans-serif;
    background-color: var(--blue);
    border-radius: 5px;
    color: var(--white);
    margin-bottom: 0.5em;
  }
  & {
    @media (max-width: 576px) {
      display: block;
      padding: 1em;
      & > div {
        &:last-child {
          text-align: end;
          margin-top: 0.5em;
        }
      }
      p {
        &.mainHeading {
          font-size: 1.5em;
          margin-bottom: 0.5em;
        }
        &.text {
          font-size: 1.15em;
        }
      }
      button {
        font-size: 1.15em;
      }
    }
  }
`;
