import { GET_INVESTOR_TEAM } from "../constant";

const initState = {
  team: [],
};

const investorReducer = function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INVESTOR_TEAM:
      return {
        ...state,
        team: payload,
      };
    default:
      return { ...state };
  }
};
export default investorReducer;
