import React, { useEffect } from "react";
import Layout from "../../../layout/Frontend";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleForum,
  resetSingleForum,
} from "../../../redux/actions/company";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import AppButton from "../../../components/typography/button";
import CustomInput from "../../../components/typography/settingCustomInput";
import { createForumComment } from "../../../utils/adminApis";

const CompanyForum = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const singleForum = useSelector((state) => state?.company?.singleForum);
  const user = useSelector((state) => state?.auth?.currentUser);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getSingleForum(params.id));
    return () => {
      dispatch(resetSingleForum(""));
    };
  }, []);

  const onFinish = (value) => {
    createForumComment(params.id, value, user).then(() => {
      dispatch(getSingleForum(params.id));
      form.resetFields();
    });
  };
  return (
    <Layout>
      <SingleForumContent>
        <div className='backBtn' onClick={() => navigate("/company/platform")}>
          <ArrowLeftOutlined />
          <span className='title'>Back</span>
        </div>

        <div className='forumContent'>
          <h2>{singleForum.topic_title}</h2>
          <p>{singleForum.description}</p>
        </div>

        <div className='commentSection'>
          <div className='comments'>
            {singleForum?.comments?.map(({ id, comment, user }) => (
              <div key={id} className='comment'>
                <div className='userImage'>
                  <img src='/images/user.png' alt='user' />
                  <div>
                    <p>{user?.first_name + " " + user?.last_name}</p>
                    <p>{comment}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Form form={form} className='commentForm' onFinish={onFinish}>
            <CustomInput
              name='comment'
              rules={[
                {
                  required: true,
                  message: "Please, enter your comment!",
                },
              ]}
              labelName='Comment'
              inputType='text'
            />
            <SubmitButton htmlType='submit' text='Post Comment' />
          </Form>
        </div>
      </SingleForumContent>
    </Layout>
  );
};

export default CompanyForum;

const SingleForumContent = styled.div`
  .backBtn {
    width: 5rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    .title {
      font-size: 1.25rem;
    }
  }

  .forumContent {
    border-bottom: 1px solid #bebebe;
    padding: 1rem;
    width: 40%;
    h2 {
      font-size: 1.75rem;
      margin: 1rem 0;
    }

    p {
      font-size: 1.25rem;
      font-weight: 400;
      font-style: normal;
    }
  }

  .commentSection {
    padding: 1rem 0;
    .comments {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 1rem;
      .comment {
        width: 40%;

        padding-bottom: 1rem;
        border-bottom: 1px solid #bebebe;
        &:last-child {
          border-bottom: none;
        }
        .userImage {
          display: flex;
          justify-content: start;
          align-items: start;
          gap: 1rem;
          img {
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            height: 2rem;
          }
          p {
            margin: 0;
            font-size: 0.875rem;
            font-weight: 400;
            font-style: normal;
          }
        }
      }
    }
    .commentForm {
      width: 40%;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: end;
      gap: 1rem;

      .ant-form-item {
        margin: 0 !important;
        width: 100%;
      }
    }
  }
`;

const SubmitButton = styled(AppButton)`
  margin: 5px 0 0 0 !important;
`;
