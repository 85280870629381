import React from "react";
import { Card } from "./styles";

const SupportCard = ({ icon, text, onPress }) => {
  return (
    <>
      <Card onClick={onPress}>
        {icon}
        <p>{text}</p>
      </Card>
    </>
  );
};

export default SupportCard;
