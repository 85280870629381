import React, { useState, useEffect } from "react";
import { LoginArea, LoginCard, loginCardStyles } from "./styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Form } from "antd";
import CustomInput from "../../components/typography/customInput";
import PasswordInput from "../../components/typography/loginPasswordField";
import EmailInput from "../../components/typography/emailInput";
import AppButton from "../../components/typography/button";
import { useDispatch, useSelector } from "react-redux";
import { loginInitiate } from "../../redux/actions/auth";
import { LoadingOutlined } from "@ant-design/icons";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const onFinish = (value) => {
    dispatch(
      loginInitiate(
        value.email,
        value.password,
        location?.pathname?.split("/")[1],
        setLoading
      )
    );
  };
  const user = useSelector((state) => state?.auth?.currentUser);

  useEffect(() => {
    if (user) {
      if (user.role === "investor") {
        navigate("/investor");
      } else if (user.role === "company") {
        navigate("/company");
      } else {
        navigate("/");
      }
    }
  }, [user]);

  return (
    <>
      <div className='p-5 bg-white'>
        <img
          style={{ width: "100%", maxWidth: 150 }}
          src='/images/jaggiLogo.png'
          alt='click here'
        />
      </div>
      <LoginArea>
        <LoginCard style={loginCardStyles}>
          <h1>
            <Link
              to='/investor/login'
              className={
                location?.pathname === "/investor/login" ? "active" : ""
              }>
              Investor
            </Link>
            <span>|</span>
            <Link
              to='/company/login'
              className={
                location?.pathname === "/company/login" ? "active" : ""
              }>
              Company
            </Link>
          </h1>

          <Form
            name='normal_login'
            layout='vertical'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}>
            <EmailInput
              name='email'
              rules={[
                { required: false, message: "Please, enter the email!" },
                {
                  pattern: new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ),
                  message: "Email Address Invalid",
                },
              ]}
              labelName='Email'
              inputType='email'
            />
            <PasswordInput
              name='password'
              rules={[
                {
                  required: false,
                  message: "Please, enter the password!",
                },
              ]}
              labelName='Password'
              inputType='password'
            />
            <AppButton
              style={{ fontFamily: "'Gabarito', sans-serif" }}
              htmlType='submit'
              text={loading ? <LoadingOutlined /> : "Log In"}
            />
          </Form>
          <br />
          <a className='forgot' href='#'>
            Forgot your password?
          </a>
        </LoginCard>
      </LoginArea>
    </>
  );
};

export default Login;
