import React from "react";
import { Form, Upload, message } from "antd";
import styled from "styled-components";
import { CloudUploadOutlined } from "@ant-design/icons";

export default function DragDropUpload(props) {
  const { Dragger } = Upload;
  const uploadProps = {
    name: "file",
    multiple: false,
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    // onDrop(e) {},
  };
  return (
    <InputContainer {...props}>
      <Dragger {...uploadProps} beforeUpload={() => false} maxCount='1'>
        <p className='ant-upload-drag-icon'>
          {/* <InboxOutlined /> */}
          <CloudUploadOutlined />
          {/* <img src='/icons/Upload Cloud.svg' alt='' /> */}
        </p>
        <p className='ant-upload-text'>Drag and Drop or Browse to upload</p>
      </Dragger>
    </InputContainer>
  );
}
const InputContainer = styled(Form.Item)`
  margin-bottom: 1.5em;
  position: relative;
  label {
    color: var(--blue-ordinary) !important;
    // font-family: Inter;
    font-size: 1.125em !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    transition: 200ms;
    height: auto !important;
    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }
  }
  .ant-upload-drag {
    background: var(--white-smoke) !important;
    border: 1px solid var(--blue) !important;
  }
  .ant-input {
    width: 100%;
    height: 60px;
    background: var(--white-smoke) !important;
    border-radius: 8px;
    border: 1px solid var(--blue);
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  .ant-form-item-row {
    display: block;
  }
  .ant-form-item-label {
    overflow: unset;
    position: absolute;
    z-index: 22;
    display: inline-block;
    left: 12px;
    top: 13px;
    color: var(--blue-ordinary) !important;
    transition: 200ms;
    font-weight: 400;
    font-size: 1.125em;
    padding: 0;
    line-height: 1;
  }

  &:focus,
  &:focus-within {
    box-shadow: none;
    .ant-form-item-label {
      top: -10px;
      background: #fff;
      label {
        font-size: 12px !important;
      }
    }
  }
`;
