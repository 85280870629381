export const TableData = [
  {
    key: "1",
    name: "Investment Document",
    date: "01/14/2023",
    file: "doc.pdf",
  },
];

export const TableColumns = [
  {
    title: "Document Name",
    dataIndex: "name",
    key: "name",
    width: 300,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 300,
  },
  {
    title: "File",
    dataIndex: "file",
    key: "file",
    width: 300,
  },
];
