import React, { useState, useEffect } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import DragDropUpload from "../typography/dragDropUpload";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { updateCompany, updateReports } from "../../utils/adminApis";
import { getCompanies, getReports } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";
import CustomDatePicker from "../typography/customDatePicker";
import moment from "moment/moment";
import CustomSelect from "../typography/customSelect";

export default function EditReports({ data, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value) => {
    const formData = {
      ...value,
      file: Array.isArray(value?.file?.fileList)
        ? value?.file?.fileList.length > 0
          ? value?.file?.fileList
          : undefined
        : undefined,
    };
    setLoading(true);
    updateReports(formData, data.id).then(() => {
      setLoading(false);
      onCancel();
      dispatch(getReports());
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data, date: moment(data.date, "x") });
    }
  }, [data, form]);
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='name'
          rules={[{ required: true, message: "Please, enter the Name!" }]}
          labelName='Name'
          inputType='text'
        />
        <CustomSelect
          name='type'
          rules={[{ required: true, message: "Please, select type!" }]}
          title='Type'
          options={[
            "Corporate Documents",
            "Annual Reports",
            "Quarterly Calls",
            "Monthly Accounting",
          ]}
        />
        <CustomDatePicker
          name='date'
          rules={[{ required: true, message: "Please, enter the date!" }]}
          label='Date'
          inputType='text'
        />

        <DragDropUpload
          // label='File'
          name='file'
          multiple='false'
          // rules={[
          //   {
          //     required: true,
          //     message: "Please upload file!",
          //   },
          // ]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Edit Report"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
