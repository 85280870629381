import React, { useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import { useDispatch } from "react-redux";
import { createPortfolio } from "../../utils/adminApis";
import { getPortfolio } from "../../redux/actions/admin";
import { LoadingOutlined } from "@ant-design/icons";
import DragDropUpload from "../typography/dragDropUpload";
import CustomDatePicker from "../typography/customDatePicker";
import CustomSelect from "../typography/customSelect";

export default function AddPortfolio({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (value) => {
    setLoading(true);
    createPortfolio(value)
      .then(() => {
        setLoading(false);
        form.resetFields();
        onCancel();
        dispatch(getPortfolio());
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='name'
          rules={[{ required: true, message: "Please, enter the Name!" }]}
          labelName='Name'
          inputType='text'
        />
        <CustomDatePicker
          name='investment_date'
          rules={[
            { required: true, message: "Please, enter the investment date!" },
          ]}
          label='Investment Date'
          inputType='text'
        />
        <CustomInput
          name='investment_amount'
          rules={[
            {
              required: true,
              message: "Please, enter the  investment amount!",
            },
          ]}
          labelName='Investment Amount'
          inputType='number'
        />
        <CustomInput
          name='investment_memo'
          rules={[
            {
              required: true,
              message: "Please, enter the  investment memo!",
            },
          ]}
          labelName='Investment Memo'
          inputType='text'
        />
        <CustomSelect
          name='type'
          rules={[{ required: true, message: "Please, select type!" }]}
          title='Type'
          options={[
            "Private Investments",
            "Public Investments",
            "Subsidiary",
            "Joint Venture",
          ]}
        />

        <DragDropUpload
          label='Icon'
          name='file'
          multiple='false'
          rules={[
            {
              required: true,
              message: "Please upload icon!",
            },
          ]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Add Portfolio"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
