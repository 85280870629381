import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom/dist";
import { BiSolidRightArrow } from "react-icons/bi";
import { Bar, SidePanel, SlideArrow } from "./styles";

const Sidebar = () => {
  const location = useLocation();
  const [showBar, setShowBar] = useState(false);
  return (
    <Bar className={showBar ? "active" : ""}>
      <SidePanel className={showBar ? "active" : ""}>
        {location.pathname.includes("/company") ? (
          <>
            <Link
              className={location.pathname === "/company" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/company'>
              Home
            </Link>
            <Link
              className={
                location.pathname === "/company/platform" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/company/platform'>
              Platform
            </Link>
            <Link
              className={location.pathname === "/company/team" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/company/team'>
              Team
            </Link>
            <Link
              className={
                location.pathname === "/company/support" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/company/support'>
              Support
            </Link>
            <Link
              className={
                location.pathname === "/company/settings" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/company/settings'>
              Settings
            </Link>
          </>
        ) : location.pathname.includes("/investor") ? (
          <>
            <Link
              className={location.pathname === "/investor" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/investor'>
              Home
            </Link>
            <Link
              className={location.pathname === "/investor/fund" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/investor/fund'>
              Fund
            </Link>
            <Link
              className={location.pathname === "/investor/team" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/investor/team'>
              Team
            </Link>
            <Link
              className={
                location.pathname === "/investor/support" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/investor/support'>
              Support
            </Link>
            <Link
              className={
                location.pathname === "/investor/settings" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/investor/settings'>
              Settings
            </Link>
          </>
        ) : location.pathname.includes("/admin", "/admin/investors") ? (
          <>
            <Link
              className={location.pathname === "/admin" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/admin'>
              Home
            </Link>
            <Link
              className={
                location.pathname === "/admin/adminInvestors" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/admin/adminInvestors'>
              Investors
            </Link>
            <Link
              className={
                location.pathname === "/admin/companies" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/admin/companies'>
              Companies
            </Link>
            <Link
              className={
                location.pathname === "/admin/portfolio" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/admin/portfolio'>
              Portfolio
            </Link>
            <Link
              className={location.pathname === "/admin/reports" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/admin/reports'>
              Reports
            </Link>
            <Link
              className={location.pathname === "/admin/deals" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/admin/deals'>
              Deals
            </Link>
            <Link
              className={location.pathname === "/admin/tickets" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/admin/tickets'>
              Tickets
            </Link>
            <Link
              className={
                location.pathname === "/admin/feedback" ? "active" : ""
              }
              onClick={() => setShowBar(false)}
              to='/admin/feedback'>
              Feedback
            </Link>
            <Link
              className={location.pathname === "/admin/network" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/admin/network'>
              Network
            </Link>
            <Link
              className={location.pathname === "/admin/forum" ? "active" : ""}
              onClick={() => setShowBar(false)}
              to='/admin/forum'>
              Forum
            </Link>
          </>
        ) : (
          <></>
        )}
      </SidePanel>
      <SlideArrow onClick={() => setShowBar(!showBar)}>
        <BiSolidRightArrow
          style={showBar ? { transform: "rotate(-180deg)" } : {}}
        />
      </SlideArrow>
    </Bar>
  );
};

export default Sidebar;
