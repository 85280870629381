import React, { useState, useEffect } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import DragDropUpload from "../typography/dragDropUpload";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { updateCompany, updateNetwork } from "../../utils/adminApis";
import { getCompanies, getNetwork } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";

export default function EditNetwork({ data, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value) => {
    setLoading(true);
    updateNetwork(value, data.id).then(() => {
      setLoading(false);
      onCancel();
      dispatch(getNetwork());
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='person_name'
          rules={[
            { required: true, message: "Please, enter the Person Name!" },
          ]}
          labelName='Person Name'
          inputType='text'
        />
        <CustomInput
          name='description'
          rules={[
            { required: true, message: "Please, enter the description!" },
          ]}
          labelName='Description'
          inputType='text'
        />
        {/* <CustomInput
          name="image"
          rules={[{ required: true, message: "Please, enter the image!" }]}
          labelName="Image"
          inputType="text"
        /> */}
        <CustomInput
          name='contact_instructions'
          rules={[
            {
              required: true,
              message: "Please, enter the total Contact Instructions!",
            },
          ]}
          labelName='Contact Instructions'
          inputType='text'
        />
        <DragDropUpload
          label='Image'
          name='file'
          multiple='false'
          rules={[
            {
              required: true,
              message: "Please upload file!",
            },
          ]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Edit Network"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
