import styled from "styled-components";
export const HomeContent = styled.div`
  margin-top: 5rem;
`;

export const Links = styled.div`
  width: 100%;
  max-width: 575px;
  min-width: max-content;
  display: flex;
  justify-content: space-between;
  gap: 3.25rem;
  align-items: baseline;
  position: relative;
  margin-bottom: 2.5em;
  p {
    font-family: "Gabarito", sans-serif;
    font-size: 1.75em;
    margin: 0;
    color: var(--blue-secondary);
    cursor: pointer;
    &:hover,
    &.active {
      border-bottom: 2px solid var(--blue-secondary);
    }
  }
`;
export const Chart = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 2em 5em 2em 2em;
  .ant-select-selection-item {
    font-size: 1.5em;
    font-weight: 500;
  }
  .ant-dropdown-trigger {
    .ant-space {
      color: var(--black);
      cursor: pointer;
      .ant-space-item {
        &:first-child {
          font-size: 1.5em;
          font-family: "Gabarito", sans-serif;
        }
        &:last-child {
          span {
            vertical-align: -0.025em;
            font-family: "Gabarito", sans-serif;
            svg {
              font-size: 0.75em;
            }
          }
        }
      }
    }
  }
  p {
    font-size: 1.5em;
    font-family: "Gabarito", sans-serif;
    color: var(--black);
  }
`;
export const LineChart = styled.div`
  width: 100%;
  height: 388px;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
`;
