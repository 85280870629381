import React, { useState } from "react";
import { Col, Modal, Row } from "antd";
import { Card } from "./styles";

const ItemCard = ({
  heading,
  subheading,
  btnText,
  image,
  modalTitle,
  modalContent,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Card>
        <Modal
          title={modalTitle}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          {modalContent}
        </Modal>
        <Row gutter={{ md: 10 }}>
          <Col xl={8} lg={24} xs={24}>
            <img
              style={{
                width: "100%",
                maxWidth: 223,
                height: 223,
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: 10,
              }}
              src={image}
              alt='click here'
            />
          </Col>
          <Col xl={14} lg={24} xs={24}>
            <p>{heading}</p>
            <p>{subheading}</p>
          </Col>
          <Col
            className='offset-lg-4 pt-3 text-lg-start text-end'
            lg={14}
            xs={24}>
            <button type='button' onClick={() => setIsModalVisible(true)}>
              {btnText}
            </button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ItemCard;
