import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Head } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { logoutInitiate } from "../../redux/actions/auth";

// Function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Header = () => {
  const user = useSelector((state) => state?.auth?.currentUser);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutInitiate());
  };
  useEffect(() => {
    if (!user) {
      if (window.location.pathname.includes("admin")) navigate("/admin/login");
      else navigate("/");
    }
  }, [user]);

  // Combine the name and capitalize the first letter
  const userName = user?.name || `${user?.first_name} ${user?.last_name}`;
  const capitalizedUserName = capitalizeFirstLetter(userName);

  return (
    <>
      <Head>
        <Navbar style={{ backgroundColor: "var(--white)" }}>
          <Navbar.Brand href=''>
            <Link
              to={
                location.pathname.includes("/company")
                  ? "/company"
                  : location.pathname.includes("/admin")
                  ? "/admin"
                  : "/investor"
              }>
              <img src='/images/jaggiLogo.png' alt='click here' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <NavDropdown
                // title={user?.name || `${user?.first_name} ${user?.last_name}`}
                title={capitalizedUserName}
                id='basic-nav-dropdown'>
                {/* <NavDropdown.Item href='#'>Action</NavDropdown.Item>
                <NavDropdown.Item href='#'>Another action</NavDropdown.Item>
                <NavDropdown.Item href='#'>
                  <Link
                    to={location.pathname.includes("/admin") ? "/" : "/admin"}>
                    {location.pathname.includes("/admin")
                      ? "Investor/Company"
                      : "Admin"}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item href=''>
                  <Link to='/' onClick={handleLogout}>
                    Log Out
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Head>
    </>
  );
};

export default Header;
