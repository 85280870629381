import { db, storage, auth } from "../../firebase";
import { GET_INVESTOR_TEAM } from "../constant";

export const getTeam = (uid) => async (dispatch) => {
  const temp = [];
  const snap = await db
    .collection("investors")
    .where("invitedBy", "==", uid)
    .get();
  snap.forEach((doc) => temp.push({ id: doc.id, ...doc.data() }));
  dispatch({ type: GET_INVESTOR_TEAM, payload: temp });
};
