import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import { HomeContent } from "./styles";
import { createAssetUnderManagement } from "../../../utils/adminApis";
import { useDispatch, useSelector } from "react-redux";
import { getManagementAssets } from "../../../redux/actions/admin";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const AdminHome = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const managementAssets = useSelector(
    (state) => state?.admin?.managementAssets
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getManagementAssets());
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      assets_under_management: managementAssets[0]?.assets_under_management,
    });
  }, [form, managementAssets]);

  const onFinish = async (value) => {
    setLoading(true);
    createAssetUnderManagement(value)
      .then((data) => {
        dispatch(getManagementAssets());
        message.success("Assest Under Management Updated Successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Layout>
        <HomeContent>
          <AdminHeading heading='Home' />
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name='assets_under_management'
              initialValue={managementAssets[0]?.assets_under_management}
              rules={[
                { required: true, message: "Please, Assets Under Management!" },
              ]}>
              <Input
                style={{ appearance: "textfield" }}
                type='number'
                placeholder='Assets Under Management'
                className='custom-placeholder-color'
              />
            </Form.Item>
            <Button htmlType='submit'>
              {loading ? <LoadingOutlined /> : "Save"}
            </Button>
          </Form>
        </HomeContent>
      </Layout>
    </>
  );
};

export default AdminHome;
