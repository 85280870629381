import { combineReducers } from "redux";
import authReducer from "./auth";
import adminReducer from "./admin";
import investorReducer from "./investor";
import companyReducer from "./company";

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  company: companyReducer,
  investor: investorReducer,
});

export default rootReducer;
