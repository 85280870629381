export const CorporateTableData = [
  {
    key: "1",
    name: "LLC Operating Agreement",
    date: "01/14/2023",
    file: "Doc.pdf",
  },
];
export const CorporateTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "File",
    dataIndex: "file",
    key: "file",
  },
];
