import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  height: 200px;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    font-size: 1.7em;
    color: var(--black);
  }
  p {
    font-size: 1.286em;
    font-family: "Gabarito", sans-serif;
    color: var(--purple);
    margin: 1em 0 0;
  }
`;
