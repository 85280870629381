import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AllRoutes from "./routes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setUser } from "./redux/actions/auth";
import { auth } from "./firebase";
// test deployment
function App() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   auth.onAuthStateChanged((authUser) => {
  //     if (authUser) {
  //       dispatch(setUser(authUser));
  //     } else {
  //       dispatch(setUser(null));
  //     }
  //   });
  // }, [dispatch]);
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
