export const TableData = [
  {
    key: "1",
    name: "Pedro Kilbourn",
    invitation: "Invite Pending",
    role: "Manager",
  },
  {
    key: "2",
    name: "Hope Ormiston",
    invitation: "Accepted",
    role: "Wealth Advisor",
  },
  {
    key: "3",
    name: "Sonia Britt",
    invitation: "Accepted",
    role: "Manager",
  },
];

export const TableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 400,
    render: (text, record) => `${record.first_name} ${record.last_name}`,
  },
  {
    title: "Invitation",
    dataIndex: "invitation",
    key: "invitation",
    width: 300,
  },
  {
    title: "Role",
    dataIndex: "teamRole",
    key: "teamRole",
    width: 300,
  },
];
