import { db, storage, auth, timestamp } from "../firebase";

export const addTeamMember = (data, uid) => {
  return new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("investors")
        .doc(uid)
        .set({ ...data, createdAt: timestamp(), updatedAt: timestamp() });

      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteTeamMember = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("investors").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
