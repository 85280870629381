import React, { useState, useEffect } from "react";
import {
  TableColumns,
  TableData,
} from "../../../data/AdminData/CompaniesTableData";
import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AddButton from "../../../components/AddButton";
import DocumentsTable from "../../../components/DocumentsTable";
import { Modal, Popconfirm } from "antd";
import AddCompany from "../../../components/modals/AddCompany";
import AppButton from "../../../components/typography/button";
import EditCompany from "../../../components/modals/EditCompany";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getCompanies, getInvestor } from "../../../redux/actions/admin";
import axios from "axios";
import { deleteCompany, deleteInvestor } from "../../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const Companies = () => {
  const data = useSelector((state) => state?.admin?.companies);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();

  const handleDelete = (data) => {
    setLoading(true);
    axios({
      method: "delete",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/delete",
      data: {
        uid: data.id,
      },
    }).then((response) => {
      deleteCompany(data.id).then(() => {
        setLoading(false);
        dispatch(getCompanies());
      });
    });
  };

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  const tableRows = [];
  data &&
    data.map((item, index) => {
      tableRows.push({
        key: item?.id,
        company_name: item?.company_name ?? "N/A",
        first_name: item?.first_name,
        last_name: item?.last_name,
        email: item?.email,
        total_investment: `$${
          isNaN(item?.total_investment)
            ? 0
            : Number(item?.total_investment).toLocaleString("en-US")
        }`,
        docs: item?.docs?.url ?? "N/A",
        id: item?.id,
        actions: (
          <div style={sectionStyle}>
            <Popconfirm
              title='Delete Company'
              description='Are you sure to delete this company?'
              onConfirm={() => {
                setSelected(item);
                handleDelete(item);
              }}
              onCancel={(e) => {}}
              okText='Yes'
              cancelText='No'>
              <AppButton
                htmlType='button'
                text={
                  loading && selected.id === item.id ? (
                    <LoadingOutlined />
                  ) : (
                    <AiOutlineDelete />
                  )
                }
                style={deleteStyle}
              />
            </Popconfirm>
            <AppButton
              htmlType='button'
              text={<AiOutlineEdit />}
              style={editStyle}
              onClick={() => {
                setSelected(item);
                setEditModalVisible(true);
              }}
            />
          </div>
        ),
      });
    });
  return (
    <>
      <Layout>
        <AdminHeading heading='Companies' />
        <Modal
          title='Add Company'
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          <AddCompany onCancel={() => setIsModalVisible(false)} />
        </Modal>

        <Modal
          title='Edit Company'
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={false}>
          <EditCompany
            data={selected}
            onCancel={() => {
              setSelected("");
              setEditModalVisible(false);
            }}
          />
        </Modal>
        <AppButton
          htmlType='button'
          text='Add Company'
          style={{
            marginLeft: "auto",
            marginBottom: "1em",
            marginRight: "0",
          }}
          onClick={() => setIsModalVisible(true)}
        />
        <DocumentsTable
          odd='odd'
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              ellipsis: true,
            },
          ]}
          dataSource={tableRows || []}
        />
      </Layout>
    </>
  );
};

export default Companies;
