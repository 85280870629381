import React, { useEffect, useState } from "react";
import {
  PrivateTableColumns,
  PrivateTableData,
} from "../../../data/InvestorData/PrivateStockTableData";
import {
  InvestorsTableColumns,
  InvestorsTableData,
} from "../../../data/InvestorData/InvestorsTableData";
import Layout from "../../../layout/Frontend";
import DocumentsTable from "../../../components/DocumentsTable";
import { InvestorsPara, Liner, Links } from "./styles";
import {
  CorporateTableColumns,
  CorporateTableData,
} from "../../../data/InvestorData/CorporateTableData";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvestor,
  getPortfolio,
  getReports,
} from "../../../redux/actions/admin";
import moment from "moment";
import { Modal } from "antd";

const Fund = () => {
  const [active, setActive] = useState(1);
  const [portfolioSlide, setPortfolioSlide] = useState("Private Investments");
  const [reportSlide, setReportSlide] = useState("Corporate Documents");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const dispatch = useDispatch();
  const investorData = useSelector((state) => state?.admin?.investor);
  const portfolioData = useSelector((state) => state?.admin?.portfolio);
  const reportData = useSelector((state) => state?.admin?.reports);

  useEffect(() => {
    dispatch(getInvestor());
    dispatch(getPortfolio());
    dispatch(getReports());
  }, []);

  const investorTableRows = [];
  investorData.map((data) => {
    const millisecondsSinceEpoch =
      data?.createdAt?.seconds * 1000 + data?.createdAt?.nanoseconds / 1000000;

    const momentDate = moment(millisecondsSinceEpoch);
    investorTableRows.push({
      key: data?.id,
      name: data?.first_name + " " + data?.last_name,
      date: momentDate.format("MM/DD/YYYY"),
      amount: `$${Number(data?.investment_amount).toLocaleString("en-US")}`,
    });
  });

  const reportTableRows = [];
  reportData
    ?.filter((r) => reportSlide === r?.type)
    ?.map((data) => {
      const millisecondsSinceEpoch =
        data?.createdAt?.seconds * 1000 +
        data?.createdAt?.nanoseconds / 1000000;

      const momentDate = moment(millisecondsSinceEpoch);
      reportTableRows.push({
        key: data?.id,
        name: data?.name,
        date: momentDate.format("MM/DD/YYYY"),
        file: (
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              if (data?.docs?.name?.split(".").pop() === "pdf") {
                window.open(data?.docs?.url);
              } else {
                setSelectedDoc(data?.docs);
                setIsModalVisible(true);
              }
            }}>
            {data?.docs?.name}
          </span>
        ),
        type: data?.type,
      });
    });

  const portfolioTableRows = [];
  portfolioData
    ?.filter((p) => portfolioSlide === p?.type)
    ?.map((data) => {
      portfolioTableRows.push({
        key: data?.id,
        name: data?.name,
        docs: data?.docs,
        date: data?.investment_date,
        amount: `$${Number(data?.investment_amount).toLocaleString("en-US")}`,
        type: data?.type,
        memo: data?.investment_memo,
      });
    });
  return (
    <>
      <Layout>
        <div
          style={{
            marginBottom: "2em",
            fontFamily: "'Gabarito', sans-serif !important",
          }}>
          <Links>
            <p
              className={active === 1 ? "active" : ""}
              onClick={() => setActive(1)}>
              Portfolio
            </p>
            <p
              className={active === 2 ? "active" : ""}
              onClick={() => setActive(2)}>
              Investors
            </p>
            <p
              className={active === 3 ? "active" : ""}
              onClick={() => setActive(3)}>
              Reports
            </p>
          </Links>
        </div>
        {active === 1 ? (
          <>
            <Links className='odd odd1'>
              <p
                className={
                  portfolioSlide === "Private Investments" ? "active" : ""
                }
                onClick={() => setPortfolioSlide("Private Investments")}>
                Private Investments
              </p>
              <p
                className={
                  portfolioSlide === "Public Investments" ? "active" : ""
                }
                onClick={() => setPortfolioSlide("Public Investments")}>
                Public Investments
              </p>
              <p
                className={portfolioSlide === "Subsidiary" ? "active" : ""}
                onClick={() => setPortfolioSlide("Subsidiary")}>
                Subsidiary
              </p>
              <p
                className={portfolioSlide === "Joint Venture" ? "active" : ""}
                onClick={() => setPortfolioSlide("Joint Venture")}>
                Joint Venture
              </p>
            </Links>
            <DocumentsTable
              columns={PrivateTableColumns}
              dataSource={portfolioTableRows}
            />
          </>
        ) : active === 2 ? (
          <>
            <InvestorsPara>
              Below is a list of all investors in the Jaggi Enterprises Fund.{" "}
            </InvestorsPara>
            <DocumentsTable
              columns={InvestorsTableColumns}
              dataSource={investorTableRows}
            />
          </>
        ) : (
          <>
            <Links className='odd odd2'>
              <p
                className={
                  reportSlide === "Corporate Documents" ? "active" : ""
                }
                onClick={() => setReportSlide("Corporate Documents")}>
                Corporate Documents
              </p>
              <p
                className={reportSlide === "Annual Reports" ? "active" : ""}
                onClick={() => setReportSlide("Annual Reports")}>
                Annual Reports
              </p>
              <p
                className={reportSlide === "Quarterly Calls" ? "active" : ""}
                onClick={() => setReportSlide("Quarterly Calls")}>
                Quarterly Calls
              </p>
              <p
                className={reportSlide === "Monthly Accounting" ? "active" : ""}
                onClick={() => setReportSlide("Monthly Accounting")}>
                Monthly Accounting
              </p>
            </Links>
            <Modal
              title=''
              width={1000}
              open={isModalVisible}
              // closable={false}
              onCancel={() => {
                setSelectedDoc(null);
                setIsModalVisible(false);
              }}
              footer={false}>
              <div style={{ padding: "1rem" }}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={selectedDoc && selectedDoc.url}
                  alt={selectedDoc && selectedDoc.name}
                />
              </div>
            </Modal>
            <DocumentsTable
              columns={CorporateTableColumns}
              dataSource={reportTableRows}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default Fund;
