import React, { useState, useEffect } from "react";
import { Col, Row, Dropdown, Select, Space, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  TableColumns,
  TableData,
} from "../../../data/InvestorData/InvestmentTableData";
import Layout from "../../../layout/Frontend";
import InvestmentCard from "../../../components/Cards/InvestmentCard";
import DocumentsTable from "../../../components/DocumentsTable";
import PerformanceChart from "../../../components/Charts/PerformanceChart";
import { Chart, HomeContent, LineChart, Links } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getManagementAssets,
  getManagementAssetsHistory,
} from "../../../redux/actions/admin";
import moment from "moment";

const InvestorHome = () => {
  const [active, setActive] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.currentUser);
  const managementAssets = useSelector(
    (state) => state?.admin?.managementAssets
  );
  const managementAssetsHistory = useSelector(
    (state) => state.admin.managementAssetsHistory
  );

  const items = [];

  managementAssetsHistory?.map((item) => {
    const year = new Date(item?.time?.toDate().toDateString()).getFullYear();
    if (!items.find((i) => i?.value === year)) {
      items.push({
        label: year,
        value: year,
      });
    }
  });
  items.sort((a, b) => b?.label - a?.label);

  const [selectedYear, setSelectedYear] = useState(null);
  useEffect(() => {
    if (selectedYear === null || selectedYear === undefined) {
      setSelectedYear(items[0]?.value);
    }
  }, [selectedYear, items]);

  useEffect(() => {
    dispatch(getManagementAssets());
    dispatch(getManagementAssetsHistory());
  }, []);

  const tableRows = [
    {
      key: user?.uid,
      name: user?.docs?.name?.split(".")?.[0],
      date: moment(user?.docs?.date).format("M/D/YYYY"),
      file: (
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            if (user?.docs?.name?.split(".").pop() === "pdf") {
              window.open(user?.docs?.url);
            } else {
              setSelectedDoc(user?.docs);
              setIsModalVisible(true);
            }
          }}>
          {user?.docs?.name}
        </span>
      ),
    },
  ];

  return (
    <>
      <Layout>
        <Row className='justify-content-center' gutter={[{ md: 20 }, 20]}>
          <Col lg={8} md={12} xs={24}>
            <InvestmentCard
              heading='Total Investment'
              subheading={`$${Number(user?.investment_amount).toLocaleString(
                "en-US"
              )}`}
            />
          </Col>
          <Col lg={8} md={12} xs={24}>
            <InvestmentCard
              heading='Investment Status'
              subheading={user?.investment_status}
            />
          </Col>
          <Col lg={8} md={12} xs={24}>
            <InvestmentCard
              heading='Assets Under Management'
              subheading={`$${Number(
                managementAssets[0]?.assets_under_management
              ).toLocaleString("en-US")}`}
            />
          </Col>
        </Row>
        <HomeContent>
          <Links>
            <p
              className={active === 1 ? "active" : ""}
              onClick={() => setActive(1)}>
              Performance Chart
            </p>
            <p
              className={active === 2 ? "active" : ""}
              onClick={() => setActive(2)}>
              Investment Documents
            </p>
          </Links>
          {active === 1 ? (
            <Chart>
              <div className='text-end'>
                <Select
                  suffixIcon={
                    <DownOutlined
                      style={{ color: "black", fontSize: "0.75rem" }}
                    />
                  }
                  key={items?.[0]?.value}
                  onChange={(value) => setSelectedYear(value)}
                  defaultValue={items?.[0]?.value}
                  style={{
                    width: 120,
                  }}
                  bordered={false}
                  options={items}
                />
              </div>
              <div className='d-flex align-items-center mb-5'>
                <p className='me-5'>$</p>
                <LineChart>
                  <PerformanceChart
                    history={managementAssetsHistory}
                    selectedYear={selectedYear}
                  />
                </LineChart>
              </div>
              <div className='text-center'>
                <p>Month</p>
              </div>
            </Chart>
          ) : (
            <>
              <Modal
                title=''
                width={1000}
                open={isModalVisible}
                // closable={false}
                onCancel={() => {
                  setSelectedDoc(null);
                  setIsModalVisible(false);
                }}
                footer={false}>
                <div style={{ padding: "1rem" }}>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={selectedDoc && selectedDoc.url}
                    alt={selectedDoc && selectedDoc.name}
                  />
                </div>
              </Modal>
              <DocumentsTable columns={TableColumns} dataSource={tableRows} />
            </>
          )}
        </HomeContent>
      </Layout>
    </>
  );
};

export default InvestorHome;
