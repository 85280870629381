import styled from "styled-components";

export const Links = styled.div`
  width: 100%;
  max-width: 363px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  p {
    font-size: 26px;
    font-family: "Gabarito", sans-serif !important;
    margin: 0;
    color: var(--blue-secondary);
    cursor: pointer;
    &:hover,
    &.active {
      border-bottom: 2px solid var(--blue-secondary);
    }
  }
`;
export const PostContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 18px;
    color: var(--purple);
    margin: 0;
    font-family: "Gabarito", sans-serif;
  }
  button {
    padding: 0.55em 1.055em;
    font-size: 1.286em;
    font-family: "Gabarito", sans-serif;
    background-color: var(--blue);
    border-radius: 5px;
    color: var(--white);
  }
  & {
    @media (max-width: 1280px) {
      display: block;
      & > div {
        &:first-child {
          margin-bottom: 1em;
        }
      }
    }
    @media (max-width: 576px) {
      p {
        font-size: 1.286em;
      }
      button {
        font-size: 1.15em;
      }
    }
  }
`;
