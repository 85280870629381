import React, { useState, useEffect } from "react";
import { LoginArea, LoginCard, loginCardStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import CustomInput from "../../../components/typography/customInput";
import PasswordInput from "../../../components/typography/loginPasswordField";
import EmailInput from "../../../components/typography/emailInput";
import AppButton from "../../../components/typography/button";
import { useDispatch, useSelector } from "react-redux";
import { loginInitiate } from "../../../redux/actions/auth";
import { LoadingOutlined } from "@ant-design/icons";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (value) => {
    dispatch(loginInitiate(value.email, value.password, "admin", setLoading));
  };
  const user = useSelector((state) => state?.auth?.currentUser);
  useEffect(() => {
    if (user?.role === "admin") {
      navigate("/admin");
    }
  }, [user]);
  return (
    <>
      <div className='p-5 bg-white'>
        <img
          style={{ width: "100%", maxWidth: 150 }}
          src='/images/jaggiLogo.png'
          alt='click here'
        />
      </div>
      <LoginArea>
        <LoginCard style={loginCardStyles}>
          <Form
            name='normal_login'
            layout='vertical'
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}>
            <h1>Admin Panel</h1>
            {/* <CustomInput
              name='email'
              rules={[
                { required: false, message: "Please, enter the email!" },
                {
                  pattern: new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ),
                  message: "Email Address Invalid",
                },
              ]}
              labelName='Email'
              inputType='email'
            /> */}
            <EmailInput
              name='email'
              rules={[
                { required: false, message: "Please, enter the email!" },
                {
                  pattern: new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ),
                  message: "Email Address Invalid",
                },
              ]}
              labelName='Email'
              inputType='email'
            />
            <PasswordInput
              name='password'
              rules={[
                {
                  required: false,
                  message: "Please, enter the password!",
                },
              ]}
              labelName='Password'
              inputType='password'
            />
            <AppButton
              htmlType='submit'
              text={loading ? <LoadingOutlined /> : "Log In"}
            />
          </Form>
          {/* <input type="email" placeholder="Email" />
          <input type="password" placeholder="Password" /> */}
          {/* <button className="loginBtn" onClick={() => navigate("/admin/home")}>
            Log In
          </button> */}
        </LoginCard>
      </LoginArea>
    </>
  );
};

export default Admin;
