import React, { useState } from "react";
import { LuMailOpen } from "react-icons/lu";
import { TfiEmail } from "react-icons/tfi";
import { FaRegStar } from "react-icons/fa";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { Col, Modal, Row } from "antd";
import Layout from "../../../layout/Frontend";
import SupportCard from "../../../components/Cards/SupportCard";
import AdminHeading from "../../../components/AdminHeading";
import SubmitTicket from "../../../components/modals/SubmitTicket";
import Feedback from "../../../components/modals/Feedback";

const CompanySupport = () => {
  const [ticketModal, setTicketModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  return (
    <>
      <Modal
        title='Submit a Ticket'
        open={ticketModal}
        onCancel={() => setTicketModal(false)}
        footer={false}>
        <SubmitTicket onCancel={() => setTicketModal(false)} />
      </Modal>
      <Modal
        title='Feedback'
        open={feedbackModal}
        onCancel={() => setFeedbackModal(false)}
        footer={false}>
        <Feedback onCancel={() => setFeedbackModal(false)} />
      </Modal>
      <Layout>
        <AdminHeading heading='Support' />
        <Row style={{ marginTop: "3em" }} gutter={[{ md: 30 }, 30]}>
          <Col md={12} xs={24}>
            <SupportCard
              icon={<LuMailOpen />}
              text='Submit a Ticket'
              onPress={() => setTicketModal(true)}
            />
          </Col>
          <Col md={12} xs={24}>
            <SupportCard
              icon={<TfiEmail />}
              text='Email Us'
              onPress={() =>
                window.open("mailto:support@jaggienterprises.com", "_blank")
              }
            />
          </Col>
          <Col md={12} xs={24}>
            <SupportCard
              icon={<FaRegStar />}
              text='Leave Feedback'
              onPress={() => setFeedbackModal(true)}
            />
          </Col>
          <Col md={12} xs={24}>
            <SupportCard
              icon={<HiOutlineDocumentDuplicate />}
              text='Documentation'
              onPress={() =>
                window.open("https://www.jaggienterprises.com", "_blank")
              }
            />
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default CompanySupport;
