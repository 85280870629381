import app, { db, storage, auth, timestamp } from "../firebase";
import { message } from "antd";

export const addTeamMember = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("companies")
        .doc(uid)
        .set({ ...data, createdAt: timestamp(), updatedAt: timestamp() });

      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });

export const deleteTeamMember = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("companies").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });

export const createTicket = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("tickets")
        .add({ ...data, createdAt: timestamp(), updatedAt: timestamp() })
        .then((ticket) => {
          message.success("Ticket created successfully");
          app
            .firestore()
            .collection("mail")
            .doc(ticket.id)
            .set({
              to: [data?.user?.email],
              message: {
                html: `
                <p>A new support ticket was created on Jaggi Platorm</p>
                <p>Your support ticket id is ${data?.ticketID}</p>              
                <p>Our support team will contact you with in 24 hours. You can also reach us directly at any time by replying to this email. </p>
                <p>Thank you for being a memeber of Jaggi Platform</a>
                `,
                subject: "Jaggi Platform - New Support Ticket",
                text: ``,
              },
            });
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });

export const deleteTicket = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("tickets").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });

export const updateTicket = (data, id) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("tickets")
        .doc(id)
        .update({ ...data, updatedAt: timestamp() })
        .then(() => {
          message.success("Ticket updated successfully");
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });

export const addFeedback = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("feedback")
        .add({ ...data, createdAt: timestamp(), updatedAt: timestamp() })
        .then(() => {
          message.success("Feedback submitted successfully");
        });

      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
