import { ResponsiveLine } from "@nivo/line";
import { monthSort } from "../../../utils/helper";

const PerformanceChart = ({ history, selectedYear }) => {
  const data = [];

  history
    ?.filter(
      (h) =>
        new Date(h?.time?.toDate().toDateString()).getFullYear() ===
        selectedYear
    )
    ?.map((item) => {
      const fullMonth = new Date(
        item?.time?.toDate().toDateString()
      ).toLocaleString("en-US", {
        month: "long",
      });

      data.push({
        x: fullMonth,
        y: item?.assets_under_management,
      });
    });
  data.sort(monthSort);

  const PerformanceChartData = [
    {
      id: "Assets Under Management History",
      color: "#000000",
      data,
    },
  ];
  return (
    <>
      <ResponsiveLine
        data={PerformanceChartData}
        margin={{ top: 20, right: 70, bottom: 14, left: 15 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        yFormat=' >-.2f'
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        colors={"#000000"}
        pointSize={10}
        pointColor='#000000'
        pointBorderWidth={10}
        pointBorderColor='#001a56'
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[]}
      />
    </>
  );
};

export default PerformanceChart;
