import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../screens/Login";
import CompanyHome from "../screens/CompanyScreens/Home";
import Platform from "../screens/CompanyScreens/Platform";
import CompanyTeam from "../screens/CompanyScreens/Team";
import CompanySupport from "../screens/CompanyScreens/Support";
import CompanySettings from "../screens/CompanyScreens/Settings";
import InvestorHome from "../screens/InvestorScreens/Home";
import Fund from "../screens/InvestorScreens/Fund";
import InvestorTeam from "../screens/InvestorScreens/Team";
import InvestorSupport from "../screens/InvestorScreens/Support";
import InvestorSettings from "../screens/InvestorScreens/Settings";
import Admin from "../screens/AdminPanel/Login";
import AdminHome from "../screens/AdminPanel/Home";
import Investors from "../screens/AdminPanel/Investors";
import Companies from "../screens/AdminPanel/Companies";
import Portfolio from "../screens/AdminPanel/Portfolio";
import Reports from "../screens/AdminPanel/Reports";
import Deals from "../screens/AdminPanel/Deals";
import Network from "../screens/AdminPanel/Network";
import Forum from "../screens/AdminPanel/Forum";
import Layout from "../layout";
import CompanyForum from "../screens/CompanyScreens/CompanyForum";
import Tickets from "../screens/AdminPanel/Tickets";
import FeedbackData from "../screens/AdminPanel/FeedbackData";

const AllRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/admin/login' element={<Admin />} />
          <Route path='/' element={<Navigate to='/investor/login' replace />} />
          <Route path='/investor/login' element={<Login />} />
          <Route path='/company/login' element={<Login />} />
          <Route element={<Layout />}>
            {/* Company Flow */}
            <Route path='/company' element={<CompanyHome />} />
            <Route path='/company/platform' element={<Platform />} />
            <Route
              path='/company/platform/forum/:id'
              element={<CompanyForum />}
            />
            <Route path='/company/team' element={<CompanyTeam />} />
            <Route path='/company/support' element={<CompanySupport />} />
            <Route path='/company/settings' element={<CompanySettings />} />

            {/* Investor Flow */}
            <Route path='/investor' element={<InvestorHome />} />
            <Route path='/investor/fund' element={<Fund />} />
            <Route path='/investor/team' element={<InvestorTeam />} />
            <Route path='/investor/support' element={<InvestorSupport />} />
            <Route path='/investor/settings' element={<InvestorSettings />} />

            {/* Admin Flow */}
            <Route path='/admin' element={<AdminHome />} />
            <Route path='/admin/adminInvestors' element={<Investors />} />
            <Route path='/admin/companies' element={<Companies />} />
            <Route path='/admin/portfolio' element={<Portfolio />} />
            <Route path='/admin/reports' element={<Reports />} />
            <Route path='/admin/deals' element={<Deals />} />
            <Route path='/admin/tickets' element={<Tickets />} />
            <Route path='/admin/feedback' element={<FeedbackData />} />
            <Route path='/admin/network' element={<Network />} />
            <Route path='/admin/forum' element={<Forum />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AllRoutes;
