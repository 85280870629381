import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Form } from "antd";
import CustomInput from "../typography/customInput";
import CustomSelect from "../typography/customSelect";
import AppButton from "../typography/button";
import { LoadingOutlined } from "@ant-design/icons";
import CustomTextArea from "../typography/customTextArea";
import { updateTicket } from "../../utils/companyApi";
import { useDispatch } from "react-redux";
import { getTickets } from "../../redux/actions/company";

export default function EditTicket({ onCancel, data }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (value) => {
    setLoading(true);
    updateTicket(value, data.id).then(() => {
      setLoading(false);
      dispatch(getTickets());
      onCancel();
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='topic'
          rules={[{ required: true, message: "Please, enter the topic!" }]}
          labelName='Topic'
          inputType='text'
        />
        <CustomInput
          name='severity'
          rules={[{ required: true, message: "Please, enter the Severity!" }]}
          labelName='Severity'
          inputType='text'
        />
        <CustomInput
          name='subject'
          rules={[{ required: true, message: "Please, enter the Subject!" }]}
          labelName='Subject'
          inputType='text'
        />
        <CustomTextArea
          name='description'
          rules={[
            { required: true, message: "Please, enter the Description!" },
          ]}
          labelName='Description'
          inputType='text'
        />
        <CustomSelect
          name='status'
          rules={[{ required: true, message: "Please, enter the status!" }]}
          title='Status'
          options={["open", "closed"]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Update Support Ticket"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
