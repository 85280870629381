import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, Radio, Slider } from "antd";
import AppButton from "../typography/button";
import { LoadingOutlined } from "@ant-design/icons";
import { addFeedback } from "../../utils/companyApi";
import { useSelector } from "react-redux";

export default function Feedback({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.auth?.currentUser);
  const [form] = Form.useForm();
  const onFinish = async (value) => {
    setLoading(true);
    addFeedback({ ...value, user }).then(() => {
      setLoading(false);
      form.resetFields();
      onCancel();
    });
  };
  const { TextArea } = Input;
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          labelCol={{ span: 24 }}
          label='How would you feel if you could no longer use Jaggi Enterprises?'
          name='feelings'>
          <Radio.Group className='my-4 d-flex flex-column align-items-start'>
            <Radio className='mb-2' value={"Very disappointed"}>
              Very disappointed
            </Radio>
            <Radio className='mb-2' value={"Some what disappointed"}>
              Some what disappointed
            </Radio>
            <Radio className='mb-2' value={"Not disappointed"}>
              Not disappointed
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label='On a scale from 0 - 10 how likely are you to recommend Jaggi Enterprises to a friend or colleague?'
          name='recommendScore'>
          <Slider min={0} max={10} defaultValue={0} className='mt-5' />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          label='How can we improve your experience?'
          name={"body"}
          rules={[
            {
              required: true,
              message: `Please enter your message`,
            },
          ]}>
          <div
            style={{
              border: "1px solid var(--textColor)",
              borderRadius: "0.2em",
            }}>
            <TextArea rows={3} />
          </div>
        </Form.Item>
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Submit"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
const P = styled("p")`
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
`;
