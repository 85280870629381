import React, { useState } from "react";
import Layout from "../../../layout/Frontend";
import InvestmentCard from "../../../components/Cards/InvestmentCard";
import DocumentsTable from "../../../components/DocumentsTable";
import {
  TableColumns,
  TableData,
} from "../../../data/CompanyData/InvestmentTableData";
import { Col, Row, Modal } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";

const CompanyHome = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const user = useSelector((state) => state?.auth?.currentUser);

  const tableRows = [
    {
      key: user?.id,
      name: user?.docs?.name?.split(".")?.[0],
      date: moment(user?.docs?.date).format("M/D/YYYY"),
      file: (
        <span
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            if (user?.docs?.name?.split(".").pop() === "pdf") {
              window.open(user?.docs?.url);
            } else {
              setSelectedDoc(user?.docs);
              setIsModalVisible(true);
            }
          }}>
          {user?.docs?.name}
        </span>
      ),
    },
  ];
  return (
    <>
      <Layout>
        <Row>
          <Col md={8} xs={24}>
            <InvestmentCard
              heading='Total Investment'
              subheading={`$${Number(user?.total_investment).toLocaleString(
                "en-US"
              )}`}
            />
          </Col>
        </Row>
        <p
          className='my-5'
          style={{
            fontSize: "26px",
            color: "var(--blue-secondary)",
            fontFamily: "'Gabarito', sans-serif",
          }}>
          Investment Documents
        </p>

        <Modal
          title=''
          width={1000}
          open={isModalVisible}
          // closable={false}
          onCancel={() => {
            setSelectedDoc(null);
            setIsModalVisible(false);
          }}
          footer={false}>
          <div style={{ padding: "1rem" }}>
            <img
              style={{ width: "100%", height: "auto" }}
              src={selectedDoc && selectedDoc.url}
              alt={selectedDoc && selectedDoc.name}
            />
          </div>
        </Modal>

        <DocumentsTable columns={TableColumns} dataSource={tableRows} />
      </Layout>
    </>
  );
};

export default CompanyHome;
