import React, { useState, useEffect } from "react";

import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AddButton from "../../../components/AddButton";
import DocumentsTable from "../../../components/DocumentsTable";
import { Modal, Popconfirm } from "antd";
import AppButton from "../../../components/typography/button";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getFeedback, getTickets } from "../../../redux/actions/company";
import { LoadingOutlined } from "@ant-design/icons";
import { deleteTicket } from "../../../utils/companyApi";
import EditTicket from "../../../components/modals/EditTicket";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const FeedbackData = () => {
  const feedbackData = useSelector((state) => state?.company?.feedback);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeedback());
  }, []);

  const TableColumns = [
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      ellipsis: true,
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      ellipsis: true,
    },

    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      ellipsis: true,
    },

    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
    },
  ];

  const tableRows = [];
  feedbackData.map((item) => {
    tableRows.push({
      key: item.id,
      experience: item?.feelings,
      rating: item?.recommendScore,
      remarks: item?.body,
      user: item?.user?.first_name + " " + item?.user?.last_name,
    });
  });
  return (
    <>
      <Layout>
        <AdminHeading heading='Feedback' />

        <DocumentsTable
          odd='odd'
          columns={TableColumns}
          dataSource={tableRows}
        />
      </Layout>
    </>
  );
};

export default FeedbackData;
