import { Image } from "antd";

export const TableData = [""];

export const TableColumns = [
  {
    title: "Company Name",
    dataIndex: "company_name",
    key: "company_name",
    ellipsis: true,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },
  {
    title: "Image",
    dataIndex: "docs",
    key: "docs",
    ellipsis: true,
    render: (text) => (
      <Image
        src={text}
        style={{ borderRadius: "50%", width: "70px", height: "70px" }}
      />
    ),
  },
  {
    title: "Redeem Instructions",
    dataIndex: "instructions",
    key: "instructions",
    ellipsis: true,
  },
];
