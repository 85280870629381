import React, { useState, useEffect } from "react";
import {
  TableColumns,
  TableData,
} from "../../../data/AdminData/ForumTableData";
import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AddButton from "../../../components/AddButton";
import DocumentsTable from "../../../components/DocumentsTable";
import { Modal, Popconfirm } from "antd";
import AddForum from "../../../components/modals/AddForum";
import AppButton from "../../../components/typography/button";
import EditForum from "../../../components/modals/EditForum";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getForum, getInvestor } from "../../../redux/actions/admin";
import axios from "axios";
import { deleteForum, deleteInvestor } from "../../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const Forum = () => {
  const data = useSelector((state) => state?.admin?.forum);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();

  const handleDelete = (data) => {
    setLoading(true);
    deleteForum(data.id).then(() => {
      setLoading(false);
      dispatch(getForum());
    });
  };

  useEffect(() => {
    dispatch(getForum());
  }, []);

  const tableRows = [];
  data.map((item) => {
    tableRows.push({
      key: item.id,
      topic_title: item.topic_title,
      description: item.description,
      comments: item.commentCount,
      posted:
        item.user.name ?? item.user.first_name + " " + item.user.last_name,
      actions: (
        <div style={sectionStyle}>
          <Popconfirm
            title='Delete Forum'
            description='Are you sure to delete this forum?'
            onConfirm={() => {
              setSelected(item);
              handleDelete(item);
            }}
            onCancel={(e) => {}}
            okText='Yes'
            cancelText='No'>
            <AppButton
              htmlType='button'
              text={
                loading && selected.id === item?.id ? (
                  <LoadingOutlined />
                ) : (
                  <AiOutlineDelete />
                )
              }
              style={deleteStyle}
            />
          </Popconfirm>
          <AppButton
            htmlType='button'
            text={<AiOutlineEdit />}
            style={editStyle}
            onClick={() => {
              setSelected(item);
              setEditModalVisible(true);
            }}
          />
        </div>
      ),
    });
  });
  return (
    <>
      <Layout>
        <AdminHeading heading='Forum' />
        <Modal
          title='Add Forum'
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          <AddForum onCancel={() => setIsModalVisible(false)} />
        </Modal>
        <Modal
          title='Edit Forum'
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={false}>
          <EditForum
            data={selected}
            onCancel={() => {
              setSelected("");
              setEditModalVisible(false);
            }}
          />
        </Modal>
        <AppButton
          htmlType='button'
          text='Add A Post'
          style={{
            marginLeft: "auto",
            marginBottom: "1em",
            marginRight: "0",
          }}
          onClick={() => setIsModalVisible(true)}
        />
        <DocumentsTable
          odd='odd'
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              ellipsis: true,
            },
          ]}
          dataSource={tableRows}
        />
      </Layout>
    </>
  );
};

export default Forum;
