import styled from "styled-components";
import { Form } from "antd";

export const InputContainer = styled(Form.Item)`
  margin-bottom: 1.5em;
  position: relative;

  .ant-input-affix-wrapper {
    border-radius: 8px;
    border: 1px solid black !important;
    background: var(--white-smoke) !important;
  }
  label {
    color: var(--blue-ordinary) !important;
    background-color: var(--white-smoke);
    font-family: "Gabarito", sans-serif;
    line-height: 1.75;
    font-size: 1.125em !important;
    transition: 200ms;
    height: auto !important;
    &:after {
      display: none;
    }
    &:before {
      display: none !important;
    }
  }
  .ant-input {
    width: 100%;
    height: 50px;
    border: 1px solid #e8e8e8;
    background: transparent !important;
    border-radius: 10px;
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  .ant-form-item-row {
    display: block;
  }
  .ant-form-item-label {
    overflow: unset;
    position: absolute;
    z-index: 22;
    display: inline-block;
    left: 12px;
    top: 13px;
    color: var(--blue-ordinary) !important;
    transition: 200ms;
    font-weight: 400;
    font-size: 1.125em;
    padding: 0;
    line-height: 1;
  }

  &:focus,
  &:focus-within {
    box-shadow: none;
    .ant-form-item-label {
      top: -14px;
      background: #fff;
      label {
        font-size: 12px !important;
      }
    }
  }
`;
