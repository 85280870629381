import axios from "axios";

export const inviteAgain = async (data, setLoading, message) => {
  setLoading(true);
  if (data.invitationStatus === "accepted") {
    message.info("Invitation already accepted!");
    setLoading(false);
  } else {
    axios({
      method: "post",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/invite",
      data: {
        email: data.email,
        displayName: data.first_name + " " + data.last_name,
        uid: data.id,
      },
    })
      .then((response) => {
        message.success("Invitation email sent!");
        setLoading(false);
      })
      .catch((error) => {
        message.error("Something went wrong!");
        setLoading(false);
      });
  }
};

// Define a custom sorting function for months
const monthOrder = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthSort = (a, b) => {
  const monthA = monthOrder.indexOf(a.x);
  const monthB = monthOrder.indexOf(b.x);
  return monthA - monthB;
};
