import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  height: auto;
  min-height: 140px;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-weight: 400;
    font-family: "Gabarito", sans-serif;
    color: var(--blue-secondary);
    margin-bottom: 0;
    &:first-child {
      font-size: 1.286em;
      margin-bottom: 0.75em;
    }
    &:last-child {
      font-size: 1.86em;
    }
  }
`;
