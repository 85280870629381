import React, { useState, useEffect } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { updateCompany, updateForum } from "../../utils/adminApis";
import { getCompanies, getForum } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";

export default function EditForum({ data, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value) => {
    setLoading(true);
    updateForum(value, data.id).then(() => {
      setLoading(false);
      onCancel();
      dispatch(getForum());
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='topic_title'
          rules={[
            { required: true, message: "Please, enter the Topic Title!" },
          ]}
          labelName='Topic Title'
          inputType='text'
        />
        <CustomInput
          name='description'
          rules={[
            { required: true, message: "Please, enter the Description!" },
          ]}
          labelName='Description'
          inputType='text'
        />
        {/* <CustomInput
          name='comments'
          rules={[{ required: true, message: "Please, enter the Comments!" }]}
          labelName='Comments'
          inputType='text'
        />
        <CustomInput
          name='posted'
          rules={[
            {
              required: true,
              message: "Please, enter the Who Posted!",
            },
          ]}
          labelName='Who Posted'
          inputType='text'
        /> */}
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Edit Forum"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
