export const InvestorsTableData = [
  {
    key: "0",
    name: "Pedro Kilbourn",
    date: "01/14/2023",
    amount: "$1,200,000",
  },
  {
    key: "1",
    name: "Hope Ormiston",
    date: "05/18/2023",
    amount: "$7,900,000",
  },
  {
    key: "2",
    name: "Sonia Britt",
    date: "08/30/2023",
    amount: "$43,000,000",
  },
];

export const InvestorsTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 400,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 300,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
];
