import React, { useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import DragDropUpload from "../typography/dragDropUpload";
import { useDispatch } from "react-redux";
import { createReports } from "../../utils/adminApis";
import { getReports } from "../../redux/actions/admin";
import { LoadingOutlined } from "@ant-design/icons";
import CustomDatePicker from "../typography/customDatePicker";
import CustomSelect from "../typography/customSelect";
export default function AddReport({ onCancel, form }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onFinish = async (value) => {
    setLoading(true);
    createReports(value)
      .then(() => {
        setLoading(false);
        onCancel();
        dispatch(getReports());
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='name'
          rules={[{ required: true, message: "Please, enter the Name!" }]}
          labelName='Name'
          inputType='text'
        />
        <CustomSelect
          name='type'
          rules={[{ required: true, message: "Please, select type!" }]}
          title='Type'
          options={[
            "Corporate Documents",
            "Annual Reports",
            "Quarterly Calls",
            "Monthly Accounting",
          ]}
        />
        <CustomDatePicker
          name='date'
          rules={[{ required: true, message: "Please, enter the date!" }]}
          label='Date'
          inputType='text'
        />

        <DragDropUpload
          // label='File'
          name='file'
          multiple='false'
          rules={[
            {
              required: true,
              message: "Please upload file!",
            },
          ]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Add Report"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
