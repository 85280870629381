import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "antd";
import CustomInput from "../typography/customInput";
import CustomSelect from "../typography/customSelect";
import AppButton from "../typography/button";
import { LoadingOutlined } from "@ant-design/icons";

export default function InviteTeamMember({
  onCancel,
  onFinish,
  loading,
  form,
}) {
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='first_name'
          rules={[{ required: true, message: "Please, enter the First Name!" }]}
          labelName='First Name'
          inputType='text'
        />
        <CustomInput
          name='last_name'
          rules={[{ required: true, message: "Please, enter the Last Name!" }]}
          labelName='Last Name'
          inputType='text'
        />
        <CustomInput
          name='email'
          rules={[{ required: true, message: "Please, enter the email!" }]}
          labelName='Email'
          inputType='email'
        />
        <CustomSelect
          name='teamRole'
          rules={[{ required: true, message: "Please, enter the role!" }]}
          title='Role'
          options={["Manager"]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Invite Team Member"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
