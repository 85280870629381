import { Input, Form } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const { TextArea } = Input;

export default function CustomTextArea(props) {
  const [inputContent, setInputContent] = useState(null);
  const handleChange = (e) => {
    if (e.target.value.length) {
      setInputContent(e.target.value);
    } else {
      setInputContent(null);
    }
  };
  return (
    <InputContainer
      name={props?.name}
      rules={props?.rules}
      className={!inputContent ? "" : "labelHold"}
      getValueProps={(value) => setInputContent(value)}
      label={props.labelName}>
      <TextArea
        rows={9}
        maxLength={1200}
        placeholder=''
        // onChange={handleChange}
        type={props.inputType}
        value={inputContent}
      />
    </InputContainer>
  );
}
const InputContainer = styled(Form.Item)`
  margin-bottom: 2.3em;
  position: relative;
  label {
    color: var(--blue-ordinary) !important;
    font-size: 1.125em;
    transition: 200ms;
    height: auto !important;
    &:after {
      display: none;
    }
    &:before {
      display: none !important;
    }
  }
  textarea {
    padding: 1rem 0 0 0;
    border: none !important;
    padding-left: 0.7rem;
    border: 1px solid var(--blue) !important;
    border-radius: 10px;
    background-color: var(--white-smoke) !important;
    font-size: 1rem;
    &:focus,
    &:hover {
      box-shadow: none !important;
    }
  }
  .ant-form-item-row {
    display: block;
  }
  .ant-form-item-label {
    overflow: unset;
    position: absolute;
    z-index: 22;
    display: inline-block;
    left: 12px;
    top: 13px;
    color: var(--blue-ordinary) !important;
    transition: 200ms;
    font-weight: 400;
    font-size: 1.125em;
  }

  &:focus,
  &:focus-within {
    box-shadow: none;
    .ant-form-item-label {
      top: -12px;
      background-color: #fff;
      label {
        font-size: 12px;
      }
    }
  }
`;
