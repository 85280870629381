import React, { useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import Layout from "../../../layout/Frontend";
import CompanyCard from "../../../components/Cards/CompanyCard";
import { IntegrateContent, Liner, Links, SettingForm } from "./styles";
import SettingTab from "./SettingTab";

const CompanySettings = () => {
  const [active, setActive] = useState(1);
  return (
    <>
      <Layout>
        <Links>
          <p
            className={active === 1 ? "active" : ""}
            onClick={() => setActive(1)}>
            Settings
          </p>
          {/* <p
            className={active === 2 ? "active" : ""}
            onClick={() => setActive(2)}
          >
            Integrations
          </p> */}
        </Links>
        <div className='mt-5'>
          {/* {active === 1 ? ( */}
          <SettingTab />
          {/* ) : (
            <IntegrateContent>
              <p className="mb-5">
                We automate data collection from our portfolio companies using
                automated Integrations.
              </p>
              <Row>
                <Col md={12}>
                  <CompanyCard
                    heading="Integration Name"
                    subheading="Integration Description"
                    btnText="Integrate"
                  />
                </Col>
              </Row>
            </IntegrateContent>
          )} */}
        </div>
      </Layout>
    </>
  );
};

export default CompanySettings;
