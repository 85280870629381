import { db, storage, auth, timestamp } from "../firebase";

export const uploadImage = (obj) =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await storage.ref(`images/${obj?.name}`);
      const url = await resp.put(obj).then(() => resp.getDownloadURL());

      return resolve(url);
    } catch (error) {
      return reject(error);
    }
  });

export const uploadDocs = (obj) =>
  new Promise(async (resolve, reject) => {
    try {
      const resp = await storage.ref(`images/${obj.name}`);
      let metaData = null;
      const url = await resp.put(obj).then((data) => {
        metaData = data.metadata;
        return resp.getDownloadURL();
      });

      return resolve({
        name: metaData.name,
        size: metaData.size,
        date: metaData.timeCreated,
        url,
      });
    } catch (error) {
      return reject(error);
    }
  });
export const createInvestor = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("investors")
        .doc(uid)
        .set({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          role: data.role,
          investment_amount: data.investment_amount,
          investment_status: data.investment_status,
          createdAt: timestamp(),
          updatedAt: timestamp(),
        })
        .then(async () => {
          if (data.file) {
            const url = await uploadDocs(data.file);
            await db.collection("investors").doc(uid).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const updateInvestor = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("investors").doc(uid).update({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        investment_amount: data.investment_amount,
        investment_status: data.investment_status,
        updatedAt: timestamp(),
      });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const deleteInvestor = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("investors").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const createCompany = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("companies")
        .doc(uid)
        .set({
          company_name: data.company_name,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          role: data.role,
          total_investment: data.total_investment,
          createdAt: timestamp(),
          updatedAt: timestamp(),
        })
        .then(async () => {
          if (data.file) {
            const url = await uploadDocs(data.file);
            await db.collection("companies").doc(uid).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const updateCompany = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("companies").doc(uid).update({
        company_name: data.company_name,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        total_investment: data.total_investment,
        updatedAt: timestamp(),
      });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const deleteCompany = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("companies").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const createPortfolio = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("portfolio")
        .add({
          name: data.name,
          type: data.type,
          investment_amount: data.investment_amount,
          investment_memo: data.investment_memo,
          investment_date: data.investment_date.format("x"),
          createdAt: timestamp(),
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          console.log(res.id);
          if (data.file) {
            const url = await uploadImage(data.file.fileList[0].originFileObj);
            await db.collection("portfolio").doc(res.id).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const updatePortfolio = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("portfolio")
        .doc(uid)
        .update({
          name: data.name,
          type: data.type,
          investment_amount: data.investment_amount,
          investment_memo: data.investment_memo,
          investment_date: data.investment_date.format("x"),
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          if (data?.file) {
            const url = await uploadImage(data?.file[0]?.originFileObj);
            await db.collection("portfolio").doc(uid).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const deletePortfolio = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("portfolio").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const createReports = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("reports")
        .add({
          name: data.name,
          type: data.type,
          date: data.date.format("x"),
          createdAt: timestamp(),
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          if (data.file) {
            const url = await uploadDocs(data.file.fileList[0].originFileObj);
            await db.collection("reports").doc(res.id).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const updateReports = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("reports")
        .doc(uid)
        .update({
          name: data?.name,
          type: data?.type,
          date: data?.date.format("x"),
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          if (data.file) {
            const url = await uploadImage(data?.file[0]?.originFileObj);
            await db.collection("reports").doc(uid).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const deleteReports = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("reports").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const createDeals = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("deals")
        .add({
          company_name: data.company_name,
          description: data.description,
          redeem_instructions: data.redeem_instructions,
          createdAt: timestamp(),
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          if (data.file) {
            const url = await uploadImage(data.file.fileList[0].originFileObj);
            await db.collection("deals").doc(res.id).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const updateDeals = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("deals")
        .doc(uid)
        .update({
          company_name: data.company_name,
          description: data.description,
          redeem_instructions: data.redeem_instructions,
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          if (data.file) {
            const url = await uploadImage(data.file.fileList[0].originFileObj);
            await db.collection("deals").doc(uid).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const deleteDeals = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("deals").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const createNetwork = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("network")
        .add({
          person_name: data.person_name,
          description: data.description,
          contact_instructions: data.contact_instructions,
          createdAt: timestamp(),
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          if (data.file) {
            const url = await uploadImage(data.file.fileList[0].originFileObj);
            await db.collection("network").doc(res.id).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const updateNetwork = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("network")
        .doc(uid)
        .update({
          person_name: data.person_name,
          description: data.description,
          contact_instructions: data.contact_instructions,
          updatedAt: timestamp(),
        })
        .then(async (res) => {
          if (data.file) {
            const url = await uploadImage(data.file.fileList[0].originFileObj);
            await db.collection("network").doc(uid).update({
              docs: url,
            });
          }
        });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const deleteNetwork = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("network").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const createForum = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("forum")
        .add({ ...data, createdAt: timestamp(), updatedAt: timestamp() });

      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const createForumComment = (id, data, user) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("forum")
        .doc(id)
        .collection("comments")
        .add({ ...data, user, createdAt: timestamp(), updatedAt: timestamp() });

      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });

export const updateForum = (data, uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db
        .collection("forum")
        .doc(uid)
        .update({ ...data, updatedAt: timestamp() });
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
export const deleteForum = (uid) =>
  new Promise(async (resolve, reject) => {
    try {
      await db.collection("forum").doc(uid).delete();
      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });

export const createAssetUnderManagement = (data) =>
  new Promise(async (resolve, reject) => {
    try {
      const collectionRef = db.collection("management_assets");

      const querySnapshot = await collectionRef.get();

      if (querySnapshot.empty) {
        await collectionRef.add({
          ...data,
          createdAt: timestamp(),
          updatedAt: timestamp(),
        });
      } else {
        const firstDoc = querySnapshot.docs[0];
        await firstDoc.ref.update({ ...data, updatedAt: timestamp() });
      }

      return resolve("success");
    } catch (error) {
      reject(error);
    }
  });
