import React, { useState, useEffect } from "react";
import {
  TableColumns,
  TableData,
} from "../../../data/AdminData/NetworkTableData";
import Layout from "../../../layout/Frontend";
import AdminHeading from "../../../components/AdminHeading";
import AddButton from "../../../components/AddButton";
import DocumentsTable from "../../../components/DocumentsTable";
import { Modal, Popconfirm } from "antd";
import AddNetwork from "../../../components/modals/AddNetwork";
import AppButton from "../../../components/typography/button";
import EditNetwork from "../../../components/modals/EditNetwork";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getInvestor, getNetwork } from "../../../redux/actions/admin";
import axios from "axios";
import { deleteInvestor, deleteNetwork } from "../../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";

const deleteStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "red",
  border: "1px solid red",
  margin: "0",
  marginRight: "1em",
};
const editStyle = {
  minWidth: "50px",
  background: "transparent",
  color: "green",
  border: "1px solid green",
  margin: "0",
};
const sectionStyle = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
};

const Network = () => {
  const data = useSelector((state) => state?.admin?.network);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const dispatch = useDispatch();

  const handleDelete = (data) => {
    setLoading(true);
    deleteNetwork(data.id).then(() => {
      setLoading(false);
      dispatch(getNetwork());
    });
  };

  useEffect(() => {
    dispatch(getNetwork());
  }, []);
  return (
    <>
      <Layout>
        <AdminHeading heading='Network' />
        <Modal
          title='Add Network'
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={false}>
          <AddNetwork onCancel={() => setIsModalVisible(false)} />
        </Modal>
        <Modal
          title='Edit Network'
          open={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          footer={false}>
          <EditNetwork
            data={selected}
            onCancel={() => {
              setSelected("");
              setEditModalVisible(false);
            }}
          />
        </Modal>
        <AppButton
          htmlType='button'
          text='Add Network'
          style={{
            marginLeft: "auto",
            marginBottom: "1em",
            marginRight: "0",
          }}
          onClick={() => setIsModalVisible(true)}
        />
        <DocumentsTable
          odd='odd'
          columns={[
            ...TableColumns,
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              ellipsis: true,
              render: (text, record) => (
                <div style={sectionStyle}>
                  <Popconfirm
                    title='Delete Network'
                    description='Are you sure to delete this network?'
                    onConfirm={() => {
                      setSelected(record);
                      handleDelete(record);
                    }}
                    onCancel={(e) => {}}
                    okText='Yes'
                    cancelText='No'>
                    <AppButton
                      htmlType='button'
                      text={
                        loading && selected.id === record?.id ? (
                          <LoadingOutlined />
                        ) : (
                          <AiOutlineDelete />
                        )
                      }
                      style={deleteStyle}
                    />
                  </Popconfirm>
                  <AppButton
                    htmlType='button'
                    text={<AiOutlineEdit />}
                    style={editStyle}
                    onClick={() => {
                      setSelected(record);
                      setEditModalVisible(true);
                    }}
                  />
                </div>
              ),
            },
          ]}
          dataSource={data}
        />
      </Layout>
    </>
  );
};

export default Network;
