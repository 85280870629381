import React, { useState } from "react";
import styled from "styled-components";
import { Form } from "antd";
import CustomInput from "../typography/customInput";
import CustomSelect from "../typography/customSelect";
import AppButton from "../typography/button";
import { LoadingOutlined } from "@ant-design/icons";
import CustomTextArea from "../typography/customTextArea";
import { createTicket } from "../../utils/companyApi";
import { useSelector } from "react-redux";
import { nanoid, customAlphabet } from "nanoid";

export default function SubmitTicket({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.auth?.currentUser);
  const [form] = Form.useForm();
  const ticketID = customAlphabet("0123456789", 8);
  const onFinish = async (value) => {
    setLoading(true);
    createTicket({
      ...value,
      user,
      status: "open",
      ticketID: ticketID(),
    }).then(() => {
      setLoading(false);
      form.resetFields();
      onCancel();
    });
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='topic'
          rules={[{ required: true, message: "Please, enter the topic!" }]}
          labelName='Topic'
          inputType='text'
        />
        <CustomInput
          name='severity'
          rules={[{ required: true, message: "Please, enter the Severity!" }]}
          labelName='Severity'
          inputType='text'
        />
        <CustomInput
          name='subject'
          rules={[{ required: true, message: "Please, enter the Subject!" }]}
          labelName='Subject'
          inputType='text'
        />
        <CustomTextArea
          name='description'
          rules={[
            { required: true, message: "Please, enter the Description!" },
          ]}
          labelName='Description'
          inputType='text'
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Submit Support Ticket"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
