import styled from "styled-components";

export const Head = styled.div`
  .navbar {
    z-index: 2;
    padding: 0.5em 1.5em;
    .navbar-brand {
      img {
        width: 125px;
      }
    }
    a#basic-nav-dropdown {
      font-size: 18px;
      font-family: "Gabarito", sans-serif;
      color: var(--black);
      &::after {
        border: 0 solid black;
        border-width: 0 2.5px 2.5px 0;
        padding: 3px;
        transform: rotate(45deg);
        margin-left: 0.555em;
        vertical-align: 0.2em;
      }
    }
    .dropdown-menu {
      left: auto;
      right: 0;
      a {
        &:nth-child(3) {
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            font-size: 18px;
            font-weight: 500;
            font-family: "Gabarito", sans-serif;
            color: var(--blue-ordinary);
          }
        }
        &:last-child {
          a {
            display: inline-block;
            justify-content: center;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 18px;
            font-family: "Gabarito", sans-serif;
            color: var(--purple);
          }
        }
      }
    }
  }
  & {
    @media (max-width: 576px) {
      .navbar {
        .navbar-brand {
          img {
            width: 200px;
          }
        }
      }
    }
  }
`;
