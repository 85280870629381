import styled from "styled-components";

export const Card = styled.div`
  padding: 1.5em 1.25em;
  background-color: var(--white);
  border-radius: 10px;
  width: 100%;
  p {
    margin: 0;
    font-family: "Gabarito", sans-serif;
    &:first-child {
      font-size: 1.86em;
      color: var(--blue-ordinary);
    }
    &:last-child {
      font-size: 1.286em;
      color: var(--purple);
    }
  }
  button {
    padding: 0.55em 1.56em;
    font-size: 1.286em;
    font-family: "Gabarito", sans-serif;
    background-color: var(--blue);
    border-radius: 5px;
    color: var(--white);
  }
  & {
    @media (max-width: 576px) {
      p {
        &:first-child {
          font-size: 1.5em;
        }
        &:last-child {
          font-size: 1.15em;
        }
      }
      button {
        font-size: 1.15em;
      }
    }
  }
`;
