export const TableData = [""];

export const TableColumns = [
  {
    title: "Company Name",
    dataIndex: "company_name",
    key: "company_name",
    ellipsis: true,
  },
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
    ellipsis: true,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
    ellipsis: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    ellipsis: true,
  },
  {
    title: "Total Investment",
    dataIndex: "total_investment",
    key: "total_investment",
    ellipsis: true,
  },
  {
    title: "Docs",
    dataIndex: "docs",
    key: "docs",
    ellipsis: true,
  },
];
