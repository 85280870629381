import {
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNIN_START,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_START,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  REGISTER_START,
  SET_USER,
} from "../constant";

import { auth, db, timestamp } from "../../firebase";
import { message } from "antd";
import {
  getCompanyUser,
  getCurrentUser,
  getInvestorUser,
} from "../../utils/authApis";
// import { registerUser } from "../../utils/api";

const registerStart = () => ({
  type: REGISTER_START,
});

const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});
const registerError = (error) => ({
  type: REGISTER_ERROR,
  payload: error,
});

const loginStart = () => ({
  type: SIGNIN_START,
});

const loginSuccess = (user) => ({
  type: SIGNIN_SUCCESS,
  payload: user,
});
const loginError = (error) => ({
  type: SIGNIN_ERROR,
  payload: error,
});

const logoutStart = () => ({
  type: LOGOUT_START,
});

const logoutSuccess = (user) => ({
  type: LOGOUT_SUCCESS,
  payload: user,
});
const logoutError = (error) => ({
  type: LOGOUT_ERROR,
  payload: error,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});
export const logoutInitiate = () => (dispatch) => {
  dispatch(logoutStart());
  auth
    .signOut()
    .then((res) => {
      dispatch(logoutSuccess());
      localStorage.removeItem("authUser");
    })
    .catch((error) => dispatch(logoutError(error.message)));
};
export const registerInitiate = (data, setLoading) => (dispatch) => {
  dispatch(registerStart());
  auth
    .createUserWithEmailAndPassword(data.email, data.password)
    .then(async ({ user }) => {
      user.updateProfile({ displayName: `${data.firstName} ${data.lastName}` });
      // await registerUser(
      //   {
      //     department: data.department,
      //     firstName: data.firstName,
      //     lastName: data.lastName,
      //     phone: data.phone,
      //     position: data.position,
      //     dob: data.dob,
      //     email: data.email,
      //     image: data.image,
      //   },
      //   user.uid
      // );
      dispatch(registerSuccess(user));
    })
    .catch((error) => {
      console.log(error);
      dispatch(registerError(error));
      message.error(error.message);
      setLoading(false);
    });
};
export const loginInitiate =
  (email, password, role, setLoading) => (dispatch) => {
    dispatch(loginStart());
    setLoading(true);
    auth
      .signInWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        if (role === "company") {
          var res = await getCompanyUser(user.uid);
          localStorage.setItem("authUser", JSON.stringify(res));

          if (res.invitationStatus === "invite pending") {
            await db.collection("companies").doc(res.id).update({
              invitationStatus: "accepted",
              lastSeen: timestamp(),
            });
            res = await getCompanyUser(user.uid);
          }

          if (res) {
            dispatch(loginSuccess({ ...res, role: "company" }));
          } else {
            dispatch(logoutInitiate());
            message.error("user not found!");
          }
        } else if (role === "investor") {
          const res = await getInvestorUser(user.uid);
          localStorage.setItem("authUser", JSON.stringify(res));

          if (res) {
            dispatch(loginSuccess({ ...res, role: "investor" }));
          } else {
            dispatch(logoutInitiate());
            message.error("user not found!");
          }
        } else if (role === "admin") {
          const res = await getCurrentUser(user.uid);
          localStorage.setItem("authUser", JSON.stringify(res));

          if (res) {
            dispatch(loginSuccess({ ...res, role: "admin" }));
            setLoading();
          } else {
            dispatch(logoutInitiate());
            message.error("user not found!");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(loginError(error));
        message.error(error.message);
      });
  };
