import React, { useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import DragDropUpload from "../typography/dragDropUpload";
import AppButton from "../typography/button";
import axios from "axios";
import { generate } from "@wcj/generate-password";
import { createInvestor } from "../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";
import { getInvestor } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";
import CustomSelect from "../typography/customSelect";

export default function AddInvestor({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (value) => {
    setLoading(true);
    const password = await generate();
    axios({
      method: "post",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/create",
      data: {
        email: value.email,
        displayName: value.first_name + " " + value.last_name,
        password: password,
        role: "investor",
      },
    })
      .then((response) => {
        createInvestor(
          {
            ...value,
            role: "investor",
            file: value.file.fileList[0].originFileObj,
          },
          response.data.uid
        ).then(() => {
          setLoading(false);
          onCancel();
          dispatch(getInvestor());
        });
      })
      .catch((error) => {
        setLoading(false);

        form.setFields([
          {
            name: "email",
            errors: [error.response.data.message],
          },
        ]);
      });
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='first_name'
          rules={[{ required: true, message: "Please, enter the First Name!" }]}
          labelName='First Name'
          inputType='text'
        />
        <CustomInput
          name='last_name'
          rules={[{ required: true, message: "Please, enter the Last Name!" }]}
          labelName='Last Name'
          inputType='text'
        />
        <CustomInput
          name='email'
          rules={[{ required: true, message: "Please, enter the email!" }]}
          labelName='Email'
          inputType='email'
        />
        <CustomInput
          name='investment_amount'
          rules={[
            {
              required: true,
              message: "Please, enter the investment amount!",
            },
          ]}
          labelName='Investment Amount'
          inputType='number'
        />
        <CustomSelect
          name='investment_status'
          rules={[{ required: true, message: "Please, select status!" }]}
          title='Investment Status'
          options={["Active", "Inactive"]}
        />
        <DragDropUpload
          label='Docs'
          name='file'
          multiple='false'
          rules={[
            {
              required: true,
              message: "Please upload file!",
            },
          ]}
        />

        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Add Investor"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
