import React, { useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import DragDropUpload from "../typography/dragDropUpload";
import { createDeals } from "../../utils/adminApis";
import { getDeals } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

export default function AddDeal({ onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (value) => {
    setLoading(true);
    createDeals(value)
      .then(() => {
        setLoading(false);
        onCancel();
        dispatch(getDeals());
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='company_name'
          rules={[
            { required: true, message: "Please, enter the Company Name!" },
          ]}
          labelName='Company Name'
          inputType='text'
        />
        <CustomInput
          name='description'
          rules={[
            { required: true, message: "Please, enter the Description!" },
          ]}
          labelName='Description'
          inputType='text'
        />
        {/* <CustomInput
          name="image"
          rules={[{ required: true, message: "Please, enter the image!" }]}
          labelName="Image"
          inputType="text"
        /> */}
        <CustomInput
          name='redeem_instructions'
          rules={[
            {
              required: true,
              message: "Please, enter the Redeem Instructions!",
            },
          ]}
          labelName='Redeem Instructions'
          inputType='text'
        />

        <DragDropUpload
          label='Image'
          name='file'
          multiple='false'
          rules={[
            {
              required: true,
              message: "Please upload file!",
            },
          ]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Add Deal"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
