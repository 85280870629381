import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { InputContainer } from "./index.styled";

export default function FormInput(props) {
  const [inputContent, setInputContent] = useState(null);
  const handleChange = (e) => {
    if (e.target.value.length) {
      setInputContent(e.target.value);
    } else {
      setInputContent(null);
    }
  };
  useEffect(() => {
    if (props.value) {
      setInputContent(props.value);
    }
  }, [props.value]);
  return (
    <>
      <InputContainer
        label={props.labelName}
        onChange={handleChange}
        name={props?.name}
        rules={props?.rules}
        className={!inputContent ? "" : "labelHold"}
        getValueProps={(value) => setInputContent(value)}>
        <Input
          placeholder={props.placeholder}
          suffix={props.suffix}
          // onChange={handleChange}
          type={props.type}
          value={inputContent}
        />
      </InputContainer>
    </>
  );
}
