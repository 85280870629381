import styled from "styled-components";

export const TeamContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5em;
  p {
    margin: 0;
  }
  a {
    max-width: 191px;
    max-height: 50px;
    padding: 10px;
    font-size: 18px;
    font-family: "Gabarito", sans-serif;
    background-color: var(--blue);
    border-radius: 5px;
    color: var(--white);
  }
`;
