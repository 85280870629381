import moment from "moment/moment";

export const TableData = [""];

export const TableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    ellipsis: true,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    ellipsis: true,
    render: (text) => moment(text, "x").format("MM/DD/YYYY"),
  },
  {
    title: "File",
    dataIndex: "docs",
    key: "docs",
    ellipsis: true,
  },
];
