import styled from "styled-components";

export const loginCardStyles = {
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  marginBottom: "5rem",
};

export const LoginArea = styled.div`
  height: calc(100vh - 157px);
  display: flex;
  background-color: var(--white);

  justify-content: center;
  align-items: center;
  & {
    @media (max-width: 576px) {
      padding: 0 2em;
    }
  }
`;
export const LoginCard = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 6em 0 2em;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Gabarito", sans-serif;
  form {
    width: 100%;
    max-width: 325px;
    margin: auto;
    text-align: center;
  }
  h1 {
    font-size: 2rem;
    cursor: pointer;
    margin-bottom: 1.75em;
    display: flex;
    a,
    p,
    span {
      color: var(--blue-secondary);
      margin: 0;
    }
    span {
      margin: 0 0.2em;
    }
    a {
      font-weight: 400;
      &.active {
        font-weight: 700;
      }
    }
  }
  // input {
  //   display: block;
  //   width: 100%;
  //   max-width: 325px;
  //   padding: 0.969em 1em;
  //   margin-bottom: 1.86em;
  //   background-color: var(--white-smoke);
  //   border: 1px solid var(--blue) !important;
  //   border-radius: 5px;
  //   font-size: 1.5em;
  //   font-weight: 400;
  //   color: var(--blue-ordinary);
  //   &::placeholder {
  //     color: var(--blue-ordinary);
  //   }
  // }
  .loginBtn {
    width: 100%;
    max-width: 228px;
    padding: 0.4em 0;
    background-color: var(--blue);

    border-radius: 5px;
    color: var(--white);
    font-size: 1.667em;
    margin-bottom: 0.75em;
  }
  .forgot {
    color: var(--blue-ordinary);
    font-size: 1.5em;
    display: block;
  }
`;
