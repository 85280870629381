import React, { useState, useEffect } from "react";
import { Col, Form, Modal, Row } from "antd";
import { DealCardsData } from "../../../data/CompanyData/DealCardsData";
import { NetworkCardsData } from "../../../data/CompanyData/NetworkCardsData";
import Layout from "../../../layout/Frontend";
import CompanyCard from "../../../components/Cards/CompanyCard";
import { Links, PostContent } from "./styles";
import ForumCard from "../../../components/Cards/ForumCard";
import { ForumCardsData } from "../../../data/CompanyData/ForumCardsData";
import { useDispatch, useSelector } from "react-redux";
import { getDeals, getForum, getNetwork } from "../../../redux/actions/admin";
import ItemCard from "../../../components/Cards/ItemCard";
import CustomInput from "../../../components/typography/customInput";
import AppButton from "../../../components/typography/button";

import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { createForum } from "../../../utils/adminApis";
import { getUserForum } from "../../../redux/actions/company";

const Platform = () => {
  const [active, setActive] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const networkData = useSelector((state) => state?.admin?.network);
  const dealData = useSelector((state) => state?.admin?.deals);
  const forumData = useSelector((state) => state?.company?.userForum);
  const user = useSelector((state) => state?.auth?.currentUser);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getNetwork());
    dispatch(getDeals());
  }, []);
  useEffect(() => {
    if (user?.id) {
      dispatch(getUserForum(user?.id));
    }
  }, [user]);

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (value) => {
    setLoading(true);
    createForum({ ...value, user })
      .then(() => {
        setLoading(false);
        onCancel();
        dispatch(getUserForum(user?.id));
        form.resetFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Layout>
        <Links>
          <p
            className={active === 1 ? "active" : ""}
            onClick={() => setActive(1)}>
            Deals
          </p>
          <p
            className={active === 2 ? "active" : ""}
            onClick={() => setActive(2)}>
            Network
          </p>
          <p
            className={active === 3 ? "active" : ""}
            onClick={() => setActive(3)}>
            Forum
          </p>
        </Links>
        <div className='mt-5 pt-3'>
          <Row gutter={[{ md: 20 }, 30]}>
            {active === 1 ? (
              <>
                {dealData.map((item, i) => {
                  return (
                    <Col lg={12} md={9} xs={24} key={i}>
                      <ItemCard
                        modalTitle={`Redeem Instructions`}
                        modalContent={item.redeem_instructions}
                        heading={item.company_name}
                        subheading={item.description}
                        image={item.docs}
                        btnText={`Redeem Deal`}
                      />
                    </Col>
                  );
                })}
              </>
            ) : active === 2 ? (
              <>
                {networkData.map((item, i) => {
                  return (
                    <Col lg={12} md={9} xs={24} key={i}>
                      <ItemCard
                        modalTitle={`Contact Instructions`}
                        modalContent={item.contact_instructions}
                        heading={item.person_name}
                        subheading={item.description}
                        image={item.docs}
                        btnText={`View Contact Information`}
                      />
                    </Col>
                  );
                })}
              </>
            ) : (
              <>
                <PostContent>
                  <div>
                    <p>
                      This is a moderated forum to let you connect and
                      collaborate with other portfolio companies.
                    </p>
                  </div>
                  <div className='text-end'>
                    <button
                      type='button'
                      onClick={() => setIsModalVisible(true)}>
                      Start a Post
                    </button>
                    <Modal
                      title='Add Forum'
                      open={isModalVisible}
                      onCancel={() => setIsModalVisible(false)}
                      footer={false}>
                      <Wrapper>
                        <Form form={form} onFinish={onFinish}>
                          <CustomInput
                            name='topic_title'
                            rules={[
                              {
                                required: true,
                                message: "Please, enter the Topic Title!",
                              },
                            ]}
                            labelName='Topic Title'
                            inputType='text'
                          />
                          <CustomInput
                            name='description'
                            rules={[
                              {
                                required: true,
                                message: "Please, enter the Description!",
                              },
                            ]}
                            labelName='Description'
                            inputType='text'
                          />

                          <AppButton
                            htmlType='submit'
                            text={loading ? <LoadingOutlined /> : "Add Post"}
                          />
                        </Form>
                      </Wrapper>
                    </Modal>
                  </div>
                </PostContent>
                {forumData.length > 0 ? (
                  forumData.map((item, i) => {
                    return <ForumCard item={item} key={i} />;
                  })
                ) : (
                  <p className='text-center'>No Forum Found</p>
                )}
              </>
            )}
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default Platform;
const Wrapper = styled("div")``;
