import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import CustomInput from "../../../components/typography/settingCustomInput";
import PasswordInput from "../../../components/typography/passwordInput";
import { useSelector, useDispatch } from "react-redux";
import AppButton from "../../../components/typography/button";
import axios from "axios";
import { updateCompany, updateInvestor } from "../../../utils/adminApis";
import { LoadingOutlined } from "@ant-design/icons";
import { setUser } from "../../../redux/actions/auth";
import { SettingForm } from "./styles";

export default function SettingTab() {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.auth?.currentUser);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value) => {
    setLoading(true);
    axios({
      method: "put",
      url: "https://us-central1-jaggi-enterprises.cloudfunctions.net/app/user/update",
      data: {
        email: value.email,
        displayName: value.first_name + " " + value.last_name,
        uid: user.id,
        password: value.password,
      },
    })
      .then((response) => {
        updateCompany(
          {
            email: value.email,
            first_name: value.first_name,
            last_name: value.last_name,
            total_investment: user.total_investment,
            company_name: user.company_name,
          },
          user.id
        ).then(() => {
          setLoading(false);
          message.success("Your settings have been updated.");
          dispatch(
            setUser({
              id: user.id,
              email: value.email,
              first_name: value.first_name,
              last_name: value.last_name,
              total_investment: user.total_investment,
              docs: user.docs,
              role: "company",
              company_name: user.company_name,
            })
          );
        });
      })
      .catch((error) => {
        form.setFields([
          {
            name: "email",
            errors: [error.response.data.message],
          },
        ]);
      });
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
  }, [user]);
  return (
    <SettingForm
      name='basic'
      form={form}
      style={{
        maxWidth: 670,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete='off'
      onFinish={onFinish}>
      <Row gutter={{ md: 20 }}>
        <Col md={12} xs={24}>
          <CustomInput
            name='first_name'
            rules={[
              {
                required: true,
                message: "Please, enter the First Name!",
              },
            ]}
            labelName='First Name'
            inputType='text'
          />
        </Col>
        <Col md={12} xs={24}>
          <CustomInput
            name='last_name'
            rules={[
              { required: true, message: "Please, enter the Last Name!" },
            ]}
            labelName='Last Name'
            inputType='text'
          />
        </Col>
      </Row>
      <CustomInput
        name='email'
        rules={[{ required: true, message: "Please, enter the email!" }]}
        labelName='Work Email'
        inputType='email'
      />
      <Row gutter={{ md: 20 }}>
        <Col md={12} xs={24}>
          <PasswordInput
            name='password'
            rules={[
              {
                required: true,
                message: "Please, enter the password!",
              },
              {
                pattern: new RegExp("^.{8,40}$"),
                message: "Password must have atleast 8 to 40 characters!",
              },
            ]}
            labelName='Password'
            inputType='password'
          />
        </Col>
        <Col md={12} xs={24}>
          <PasswordInput
            name='confirm_password'
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
            labelName='Confirm Password'
            inputType='password'
          />
        </Col>
      </Row>
      <Form.Item
        className='mt-4'
        style={{ display: "flex", justifyContent: "end" }}
        // wrapperCol={{
        //   md: {
        //     offset: 17,
        //     span: 7,
        //   },
        // }}
      >
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Save"}
        />
      </Form.Item>
    </SettingForm>
  );
}
