import React, { useState, useEffect } from "react";
import { Form } from "antd";
import styled from "styled-components";
import CustomInput from "../typography/customInput";
import AppButton from "../typography/button";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { updateCompany, updatePortfolio } from "../../utils/adminApis";
import { getCompanies, getPortfolio } from "../../redux/actions/admin";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import CustomDatePicker from "../typography/customDatePicker";
import DragDropUpload from "../typography/dragDropUpload";
import CustomSelect from "../typography/customSelect";

export default function EditPortfolio({ data, onCancel }) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (value) => {
    const formData = {
      ...value,
      file: Array.isArray(value?.file?.fileList)
        ? value?.file?.fileList.length > 0
          ? value?.file?.fileList
          : undefined
        : undefined,
    };
    setLoading(true);
    updatePortfolio(formData, data.id).then(() => {
      setLoading(false);
      onCancel();
      dispatch(getPortfolio());
    });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        investment_date: moment(data.investment_date, "x"),
      });
    }
  }, [data]);
  return (
    <Wrapper>
      <Form form={form} onFinish={onFinish}>
        <CustomInput
          name='name'
          rules={[{ required: true, message: "Please, enter the Name!" }]}
          labelName='Name'
          inputType='text'
        />

        <CustomDatePicker
          name='investment_date'
          rules={[
            { required: true, message: "Please, enter the investment date!" },
          ]}
          label='Investment Date'
          inputType='text'
        />
        <CustomInput
          name='investment_amount'
          rules={[
            {
              required: true,
              message: "Please, enter the  investment amount!",
            },
          ]}
          labelName='Investment Amount'
          inputType='number'
        />
        <CustomSelect
          name='type'
          rules={[{ required: true, message: "Please, select type!" }]}
          title='Type'
          options={[
            "Private Investments",
            "Public Investments",
            "Subsidiary",
            "Joint Venture",
          ]}
        />
        <CustomInput
          name='investment_memo'
          rules={[
            {
              required: true,
              message: "Please, enter the  investment memo!",
            },
          ]}
          labelName='Investment Memo'
          inputType='text'
        />
        <DragDropUpload
          // label='Icon'
          name='file'
          multiple='false'
          // rules={[
          //   {
          //     required: true,
          //     message: "Please upload icon!",
          //   },
          // ]}
        />
        <AppButton
          htmlType='submit'
          text={loading ? <LoadingOutlined /> : "Edit Investor"}
        />
      </Form>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
