// authorization
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNIN_START = "SIGNIN_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_START = "LOGOUT_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_START = "REGISTER_START";
export const SET_USER = "SET_USER";
export const GET_INVESTOR = "GET_INVESTOR";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_PORTFOLIO = "GET_PORTFOLIO";
export const GET_REPORTS = "GET_REPORTS";
export const GET_DEALS = "GET_DEALS";
export const GET_NETWORK = "GET_NETWORK";
export const GET_FORUM = "GET_FORUM";
export const GET_USER_FORUM = "GET_USER_FORUM";
export const GET_SINGLE_FORUM = "GET_SINGLE_FORUM";
export const RESET_SINGLE_FORUM = "RESET_SINGLE_FORUM";
export const GET_INVESTOR_TEAM = "GET_INVESTOR_TEAM";
export const GET_COMPANY_TEAM = "GET_COMPANY_TEAM";
export const GET_TICKETS = "GET_TICKETS";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_ASSETS_UNDER_MANAGEMENT_HISTORY =
  "GET_ASSETS_UNDER_MANAGEMENT_HISTORY";

export const GET_ASSETS_UNDER_MANAGEMENT = "GET_ASSETS_UNDER_MANAGEMENT";
